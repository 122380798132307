import { NavLink } from "react-router-dom";
import "./RegisterFreeInstallation.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';


const FreeInstallation = () => (

    <Helmet>
        <title>Free Installation</title>
        <meta name="description" content="Register your BLDC ceiling fan free installation with Colorbot to ensure protection and support for your products and enjoy long-lasting cooling operation." />
        <link rel="canonical" href="https://colorbot.in/free-installation" />

        <meta property="og:title" content="Free Installation" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/free-installation" />
        <meta property="og:description" content="Register your BLDC ceiling fan free installation with Colorbot to ensure protection and support for your products and enjoy long-lasting cooling operation." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/banners/instalation-banner.png" />
    </Helmet>
);

function RegisterFreeInstallation() {
    const fileInputRef = useRef(null);



    const [filename, setfilename] = useState("")
    const handleButtonClick = () => {
        setfilename("")
        fileInputRef.current.click();
    };
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);

 

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        // Do something with the selected file (e.g., upload, read contents, etc.)

        setfilename(selectedFile.name)
    };
  
    const [warrantypolicy, setwarrantypolicy] = useState(false)
    const [active, setactive] = useState(false)
    const [lgShow1, setLgShow1] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    const h3RefRegister = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isH3Visible, setIsH3Visible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const h3Position = h3RefRegister.current.offsetTop;
            const offset = window.innerHeight / 2;

            if (scrollPosition + offset >= h3Position) {

                setIsH3Visible(true);

            } else {
                //   setIsH3Visible(false);
            }
            setScrollPosition(scrollPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        // const title = h3RefRegister.current;

        // if (title) {
        //     const rect = title.getBoundingClientRect();
        //     const offset = window.innerHeight / 2;

        //     if (rect.top <= offset) {
        //         title.classList.add('active');
        //     } else {
        //         // title.classList.remove('active');
        //     }
        // }
    }, [scrollPosition])
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');


    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        setPhoneNumber(inputValue);

    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);

    };



    const handleSubmit = async () => {


        const phoneRegex = /^\d{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (name === "") {
            toast.error("Please enter your name.")
        } else if (phoneNumber === "") {
            toast.error("Please enter your phone number.")
        } else if (!phoneRegex.test(phoneNumber)) {
            toast.error("Please enter a valid 10 digit Phone Number")
        } else if (email === "") {
            toast.error("Please enter your email address.")
        } else if (!emailRegex.test(email)) {
            toast.error("Please enter a valid email address.")

        } else if (filename === "") {
            toast.error("Please upload your product invoice.")
        }

        else {
            setactive(true)

            try {

                const formData = new FormData();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('phone', phoneNumber);
                formData.append('file', file);

                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotRegisterFreeInstallation`, {
                    method: 'POST',
                    body: formData
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {
                        setactive(false)
                        setLgShow1(true)
                        toast.success(response.message)
                        setName("")
                        setEmail("")
                        setPhoneNumber("")
                       
                        setwarrantypolicy(false)
                     
                        setfilename("")

                    } else {
                        setactive(false)
                        toast.error(response.error)
                    }
                })
            } catch (error) {
                toast.error(error)
                setactive(false)
                console.log(error)
            }

        }


    };

    return (
        <>

            <section className='mt-5 pt-4'>
                <FreeInstallation />
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{
                        borderRadius: "10px"
                    }}

                    centered
                    show={lgShow1}

                    onHide={() => setLgShow1(false)}

                >
                    <Modal.Header style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0rem" }}>


                        <div className="box-profile-pop-profile">



                            <div className="logoutpop">
                                <img src="assets/successicon.svg" className="img-fluid" alt="exlam" />
                            </div>
                            <div className="logoutpop mt-2">
                                <p>Thank You! Your claim free installation has been registered successfully.</p>
                            </div>
                            <div className="logoutpop button">
                                <button className="btn btncancellog" style={{
                                    background: "#242933",
                                    padding: "6px 47px",
                                    color: "white",
                                    borderRadius: "8px"
                                }} onClick={() => setLgShow1(false)}>
                                    Ok
                                </button>

                            </div>







                        </div>


                    </Modal.Body>
                </Modal>



                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 padd-neg">
                            <div className="banner-img">
                                {/* <h3 className="banner-text">Register Warranty</h3> */}
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/banners/instalation-banner.png"}
                                    width={"100vw"}
                                    className="d-block w-100 img-fluid" alt="Register warranty"
                                    effect="blur"
                                />

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-5">
                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-8 ">
                            <div className="registertitle">
                                <h1 className={`border-bottom-animation h3hoverbefore ${isH3Visible ? 'active' : ''}`} ref={h3RefRegister}>Claim Free Installation</h1>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-usregister mt-4 p-5">
                                <div className="text-center w-100">
                                    <p className="registersubtitle">Enter details to claim free installation!!!  </p>
                                </div>
                                <input type="text" placeholder="Name*" className="form-control form-control-lg mt-4" value={name} onChange={(e) => {
                                    setName(e.target.value)
                                }} />
                                <input
                                    type="text"
                                    placeholder="Phone*"
                                    className="form-control form-control-lg mt-3"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    minLength={10}
                                    maxLength={10}

                                />

                                <input
                                    type="email"
                                    placeholder="Email*"
                                    className="form-control form-control-lg mt-3"
                                    value={email}
                                    onChange={handleEmailChange}

                                />


                                <label className="mt-3">Upload Invoice</label>
                                <button className="btn-primary btn-upload mt-2" onClick={handleButtonClick}>
                                    Choose File
                                </button> {filename}
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />

<br></br>
                                <div className="txt-cpn">
                                I have read and accepted the <NavLink to="/services-policies" target="_blank" className="link" >Warranty Policy</NavLink>
                                </div>

                                {/* <div className="form-check mt-3">
                                    <input type="checkbox" className="form-check-input" id="registerexampleCheck1" checked={warrantypolicy} onChange={() => {
                                        setwarrantypolicy(!warrantypolicy)
                                    }} />
                                    <label className="form-check-label" htmlFor="registerexampleCheck1">I have read and accepted the <NavLink to="/services-policies" target="_blank" className="link" >Warranty Policy</NavLink>.</label>
                                </div> */}
                                <button className="btn btn-lg btn-primary mt-4 mb-4 contactsubmit" disabled={active} onClick={() => {
                                    handleSubmit()
                                }}>SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />

                <br />
            </section>

      







        </>
    );
}
export default RegisterFreeInstallation;