import "./Blogdesc.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import React, { useRef, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";


const BlogDescriptionMeta = ({ blog }) => {
    const { 
        MetaTitle, 
        MetaDescription, 
        PermaLink, 
        FeaturedImage ,
        HeadScripts
    } = blog;

    return (
        MetaTitle &&
        <Helmet>
            <title>{MetaTitle}</title>
            <meta name="description" content={MetaDescription} />
            <link rel="canonical" href={PermaLink} />

            {/* Open Graph Tags */}
            <meta property="og:title" content={MetaTitle} />
            <meta property="og:site_name" content="Colorbot" />
            <meta property="og:url" content={PermaLink} />
            <meta property="og:description" content={MetaDescription} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={FeaturedImage || 'https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Blog-banner.jpg'} />

            {/* Twitter Card Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={MetaTitle} />
            <meta name="twitter:description" content={MetaDescription} />
            <meta name="twitter:image" content={FeaturedImage || 'https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Blog-banner.jpg'} />
            <script type="application/ld+json">
            {HeadScripts?HeadScripts:""}
            </script>
           
           
           

        </Helmet>
    );
};


function Blogdesc() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const h3RefDesc = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isH3Visible, setIsH3Visible] = useState(false);
    const params = useParams()
    const [blogData, setBlogData] = useState([]);
    const [blogs, setBlogs] = useState("");
  

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const h3Position = h3RefDesc.current.offsetTop;
            const offset = window.innerHeight / 2;

            if (scrollPosition + offset >= h3Position) {

                setIsH3Visible(true);

            } else {
                //   setIsH3Visible(false);
            }


            setScrollPosition(scrollPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // const title = h3RefDesc.current;

        // if (title) {
        //     const rect = title.getBoundingClientRect();
        //     const offset = window.innerHeight / 2;

        //     if (rect.top <= offset) {
        //         title.classList.add('active');
        //     } else {
        //         // title.classList.remove('active');
        //     }
        // }
    }, [scrollPosition]);

    const fetchBlogDescription = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetBlog/${id}`);
            if (response.ok) {
                const result = await response.json();
                setBlogs(result);
            }
        } catch (error) {
            console.log(error)
        }

    };
    const fetchBlogData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetAllBlog`);
            if (response.ok) {
                const result = await response.json();
                fetchBlogDescription(result.filter((item)=>item.PermaLink.includes(params.id))[0].blogId)
                setBlogData(result.reverse() || []);
            }
        } catch (error) {
            console.log(error)
        }

    };


    useEffect(() => {
        fetchBlogData()
    
    }, []);
    const ShareInfo = () => {

        if (navigator.share) {
            navigator.share({
                title: "Color Bot",
                text: "ColorBot Blogs",
                url: window.location.href
            }).then(() => {
                console.log('Share successful');
            }).catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            // Fallback for browsers that do not support Web Share API
            console.log('Web Share API not supported.');
            // Implement your own custom share functionality here
        }
    };
    return (
        <>
            <BlogDescriptionMeta blog={blogs} />
            <section className="mt-5 pt-4 mb-5">
                <div className="container-fluid ">
                    <br />
                    <div className="row">
                        <div className="col-md-1">

                        </div>
                        <div className="col-md-8">
                            <p className="blogdescbreadcrumb mb-4 mt-3"><a style={{ color: "#000000" }} href="/" > Home </a> &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <span ><a style={{ color: "#000000" }} href="/blog" > Blog </a></span> &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <span style={{ color: "#1428A1" }}>Blog Description</span></p>
                        </div>
                    </div>
                    <div className="row justify-content-center">

                        {/* <div className="col-lg-2 mt-2">
                            <div className="boxx">
                                <ul className="unordered-itemss">
                                    <li className="item1">Content</li>
                                    <li className="maya mayaactive">Installation guide</li>
                                    <li className="maya ">Bypass regulator</li>
                                    <li className="maya">Bypass regulator</li>
                                    <li className="maya">Wiring and Electrical Connection</li>
                                    <li className="maya">Proper fittings</li>
                                    <li className="maya">Downrod size</li>
                                    <li className="maya">Remote accessibility</li>
                                    <li className="maya">Conclusion</li>
                                </ul>
                            </div>
                        </div> */}


                        <div className="col-lg-8 mt-2">
                            <div className="middle-box1 pt-3">
                                <div className="middle1-text1">
                                    <h1>{blogs.Title}</h1>
                                </div>
                                <span> By Colorbot &nbsp; &nbsp; {moment(blogs.createdAt).format("MMM DD, YYYY")}</span>

                                <div className="middle-image-1">
                                    <LazyLoadImage src={blogs.FeaturedImage}

                                        className=" img-fluid " alt="..."
                                        effect="blur"
                                    />

                                </div>
                                <div className="middle1-text2">
                                    <span dangerouslySetInnerHTML={{ __html: blogs.PostContent }}></span>

                                </div>
                            </div>
                        </div>



                        <div className="col-lg-2 mt-2">


                            <div className="box-1">
                                <div className="right1-text1">
                                    <h4>Share this article</h4>
                                </div>
                                <div className="iconn">

                                    {/* <a href="javascript void(0)">
                                        <div className="icon-1">
                                        <i className="fa fa-facebook-f"></i>
                                        </div>
                                    </a>

                                    <a href="javascript void(0)">
                                        <div className="icon-2">
                                        <i className="fa fa-twitter"></i>
                                        </div>
                                    </a>
                                    <a href="javascript void(0)">
                                        <div className="icon-3">
                                        <i className="fa fa-whatsapp"></i>
                                        </div>
                                    </a>
                                    <a href="javascript void(0)">
                                        <div className="icon-4">
                                        <i className="fa fa-copy"></i>
                                        </div>
                                    </a> */}

                                    <span style={{ cursor: "pointer" }} onClick={() => {
                                        ShareInfo()
                                    }}>Share &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/share.svg" alt="share" className="img-fluid" /> </span>


                                </div>
                            </div>




                            {/* <div className="box-2">
                                <div className="right1-text2">
                                    <h4>Subscribe for Weekly updates</h4>
                                </div>
                                <div className="email-2">
                                    <input type="email" className="form-control" placeholder="Enter your email" />

                                </div>

                                <button type="button" className="btn buttton button1">Subscribe</button>


                            </div> */}




                            <div className="box-3">
                                <div className="header5">
                                    <h5>Recents</h5>
                                </div>
                                <div className="unordered2-list2">
                                    <ul>
                                        {blogData.filter((item) => item.PostType === "publish").length > 0 && blogData.filter((item) => item.PostType === "publish").slice(0, 5).map((blog, index) => (
                                            <li key={index}><a href={`/blog-info/${blog?.PermaLink?.split("/")[4]}`}>{blog.Title.slice(0, 50) + "..."}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>



                        </div>
                    </div>

                    {/* <div className="row justify-content-center mt-5">
                        <div className="desctitle">
                            <h3 className={`border-bottom-animation ${isH3Visible ? 'active' : ''}`} ref={h3RefDesc}>Frequently Asked Questions</h3>
                            <br />

                        </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-6 mt-2">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </section>
        </>
    );
}
export default Blogdesc