
import "./Buynoworder.css"
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import ProductQuantitySelect from "../Shared/QuantityModule";
function Buynoworder(props) {
    const [Subtotal, setSubtotal] = useState(0);
    const [addressdata, setaddressdata] = useState([])
    const [activeaddress, setactiveaddress] = useState(0)
    const [extendedwarranty, setextendedwarranty] = useState(0)
    const [shippingPrice, setShippingPrice] = useState(0)
    const [totalextendedwarranty, settotalextendedwarranty] = useState(0)
    const [addemail, setaddemail] = useState("");
    const [gstno, setgstno] = useState("");
    const [gstname, setgstname] = useState("");

    useEffect(() => {
        if (window.localStorage.getItem("Email") !== null && window.localStorage.getItem("Email") !== "") {
            setaddemail(window.localStorage.getItem("Email"))
        } else {
            if (window.localStorage.getItem("addorderemail") !== null) {
                setaddemail(window.localStorage.getItem("addorderemail"))
            }
        }

        if (window.localStorage.getItem("OrderGst") !== null) {
            setgstno(window.localStorage.getItem("OrderGst"))
            setgstname(window.localStorage.getItem("OrderGstBussinessName"))
            setgstenabled(true)
        }


        var sum = 0;
        for (let i = 0; i < props.cartdata.length; i++) {
            sum = sum + Number(props.cartdata[i].productdiscountedprice)
        }
        if (props.cartdata.length === 0) {
            localStorage.removeItem("activeaddress")
        }
        var extendedwarrantysum = 0
        var totalextendedwarrantysum = 0
        var shippingtotalprice = 0
        for (let i = 0; i < props.cartdata.length; i++) {
            if (props.cartdata[i].extendedwarranty === true) {
                sum = sum + 0
                extendedwarrantysum = (extendedwarrantysum + 199) * Number(props.cartdata[i].productquantity)
                totalextendedwarrantysum = (totalextendedwarrantysum + 499) * Number(props.cartdata[i].productquantity)
                shippingtotalprice = (shippingtotalprice + 100) * Number(props.cartdata[i].productquantity)


            } else {
                sum = sum + 0
                shippingtotalprice = (shippingtotalprice + 100) * Number(props.cartdata[i].productquantity)
            }

        }
        setShippingPrice(shippingtotalprice)
        settotalextendedwarranty(totalextendedwarrantysum)
        const coupandata = coupanlist.filter((data) => {
            return data.coupon_code === coupancode.toUpperCase()
        })

        if (coupandata.length > 0) {
            if (coupandata[0].discount_type === "Rs.") {
                if (sum < Number(coupandata[0].min_cart_value)) {
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setCoupanActive(false)
                }
            } else {
                if (sum < Number(coupandata[0].min_cart_value)) {
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setCoupanActive(false)
                }
            }
        }
        setSubtotal(sum)

        setextendedwarranty(extendedwarrantysum)
        if (window.localStorage.getItem("Address")) {

            setaddressdata(JSON.parse(window.localStorage.getItem("Address")))
        }
        if (window.localStorage.getItem("activeaddress")) {
            setactiveaddress(window.localStorage.getItem("activeaddress"))
        }


    }, [props])

    const navigate = useNavigate()
    const removeItem = (productId) => {
        props.setcartdata(prevData => {
            const updatedData = prevData.filter(item => item.productid !== productId);
            window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
            return updatedData;
        });

        if (props.cartdata.length === 1) {
            localStorage.removeItem("activeaddress")
            navigate('/bldc-ceiling-fan');
        }
    };
    const handlewarranty = (productId, check) => {
        var checked = check === "false" ? true : false

        props.setcartdata(prevData => {
            const updateddata = prevData.map(item => {
                if (item.productid === productId) {
                    return { ...item, extendedwarranty: checked };
                }
                return item;
            })
            window.localStorage.setItem('cartdata', JSON.stringify(updateddata));
            window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
            window.localStorage.setItem("addcart", true)

            return updateddata

        })



    }
    const confirmemail = () => {
        for (let i = 0; i < props.cartdata.length; i++) {

            if (parseInt(props.cartdata[i].productquantity) > parseInt(props.cartdata[i].inventory)) {
                toast.error(`Please check product ${i + 1} is out of stock`); // Display an error toast notification
                return
            }
        }




        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(addemail) === false) {
            toast.error("Please enter a valid Email"); // Display an error toast notification

        } else if (gstenabled) {
            if (gstno === "") {
                toast.error("Please enter a GSTIN"); // Display an error toast notification
            } else if (/^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/.test(gstno) === false) {
                toast.error("Please enter a valid GSTIN"); // Display an error toast notification

            } else if (gstname === "") {
                toast.error("Please enter a GST Business Name"); // Display an error toast notification
            } else {
                window.localStorage.setItem("addorderemail", addemail)
                window.localStorage.setItem("OrderGst", gstno)
                window.localStorage.setItem("OrderGstBussinessName", gstname)

                navigate('/product_buy_now_payment');
            }

        } else {
            window.localStorage.setItem("addorderemail", addemail)
            navigate('/product_buy_now_payment');
        }
    }

    const handleChangeQuantity = (productId, newQuantity) => {
        if (newQuantity === "" || newQuantity === 0) {
            toast.error("Please enter a valid Quantity"); // Display an error toast notification
            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {
                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        } else {
            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {
                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        }

        if(window.localStorage.getItem("coupancode") !== null){
            applyCoupan(window.localStorage.getItem("coupancode"))
        }
    };
    const [showMore, setShowMore] = useState(false);
    const [showMore1, setShowMore1] = useState(false);
    const [showarr, setShowArr] = useState([0]);
    const [showarr1, setShowArr1] = useState([0]);

    const toggleShowMore = () => {
        setShowMore(!showMore);
        if (showMore) {
            setShowArr([0]);
        } else {
            setShowArr(props.cartdata.map((_, index) => index));
        }
    };
    const toggleShowMore1 = () => {
        setShowMore1(!showMore1);
        if (showMore1) {
            setShowArr1([0]);
        } else {
            setShowArr1(props.cartdata.map((_, index) => index));
        }
    };
    const [gstenabled, setgstenabled] = useState(false)
    const [lgShow1, setLgShow1] = useState(false);

    const [coupanlist, setcoupanlist] = useState([])
    const [coupancode, setcoupancode] = useState("")
    const [coupanprice, setcoupanprice] = useState(0)
    const [userlist, setUserList] = useState([])
    const getUser = async () => {
        try {

            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetUserCoupan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
                body: JSON.stringify({ UserId: window.localStorage.getItem("UserId") })

            });

            if (response.status === 200) {
                const data = await response.json();
                setUserList(data.data)
                getCoupan()

            }
        } catch (error) {
            console.error('API Error:', error);

        }
    }
    const getCoupan = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetWebCoupan`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                let filterdata = []
                for (let i = 0; i < data.data.length; i++) {


                    filterdata.push(data.data[i])




                }

                for (let i = 0; i < userlist.length; i++) {
                    for (let j = 0; j < filterdata.length; j++) {
                      
                        if (userlist[i].coupanid === filterdata[j].coupon_code && filterdata[j].gift === "first") {
                            if(window.localStorage.getItem("coupancode") === filterdata[j].coupon_code){
                                setcoupanprice(0)
                                window.localStorage.removeItem("coupanprice")
                                window.localStorage.removeItem("coupancode")
                            }
                            filterdata.splice(j, 1)
                        }
                        if (userlist[i].coupanid === filterdata[j].coupon_code && filterdata[j].gift === "gift") {
                            if(window.localStorage.getItem("coupancode") === filterdata[j].coupon_code){
                                setcoupanprice(0)
                                window.localStorage.removeItem("coupanprice")
                                window.localStorage.removeItem("coupancode")
                            }
                            filterdata.splice(j, 1)
                        }
                    }
                }

                var filterdata1 = []
                for (let i = 0; i < filterdata.length; i++) {
                    if (Number(filterdata[i].noOfUsers) > 0 || filterdata[i].noOfUsers === undefined || filterdata[i].noOfUsers === "") {
                        filterdata1.push(filterdata[i])
                    }

                }


                setcoupanlist(filterdata1)

            }
        } catch (error) {
            console.error('API Error:', error);

        }
    }
    const applyCoupan = async (code) => {
        if (code === "") {
            const data = coupanlist.filter((data) => {
                return data.coupon_code === coupancode.toUpperCase()
            })
            if (data.length > 0) {
                if (parseInt(Subtotal) > parseInt(data[0].discount)) {
                    if (data[0].discount_type === "Rs.") {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice(data[0].discount)
                            window.localStorage.setItem("coupanprice", parseInt(data[0].discount))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                            setCoupanActive(true)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    } else {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice((Subtotal * data[0].discount) / 100)
                            window.localStorage.setItem("coupanprice", ((Subtotal * parseInt(data[0].discount)) / 100))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    }
                } else {
                    toast.error("Amount should be greater than " + data[0].discount)
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setLgShow1(false);
                    setCoupanActive(false)
                }
            } else {
                toast.error("Please enter a valid coupon code")
            }

        } else {
            const data = coupanlist.filter((data) => {
                return data.coupon_code === code
            })
            if (data.length > 0) {
                if (parseInt(Subtotal) > parseInt(data[0].discount)) {
                    if (data[0].discount_type === "Rs.") {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice(parseInt(data[0].discount))
                            window.localStorage.setItem("coupanprice", parseInt(data[0].discount))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    } else {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice((Subtotal * data[0].discount) / 100)
                            window.localStorage.setItem("coupanprice", ((Subtotal * parseInt(data[0].discount)) / 100))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    }
                } else {
                    toast.error("Amount should be greater than " + data[0].discount)
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setLgShow1(false);
                    setCoupanActive(false)
                }
            } else {
                toast.error("Please enter a valid coupon code")
            }

        }
    }

    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Summary - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Summary - Colorbot"; // Reset the title when component unmounts
        };
    }, []);
    const [coupanActive, setCoupanActive] = useState(false)
    useEffect(() => {
        if (window.localStorage.getItem("coupanprice")) {
            setcoupanprice(parseInt(window.localStorage.getItem("coupanprice")))
        }
        if (window.localStorage.getItem("coupancode")) {
            setcoupancode(window.localStorage.getItem("coupancode"))
            setCoupanActive(true)
        } else {

            setCoupanActive(false)
        }

        getUser()
    }, [lgShow1])
    const navigateToProduct = (path) => {
        navigate(path);
    }
    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem("cartdata")).length === 0) {
            navigate("/bldc-ceiling-fan")
        }
    }, [])
    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const convertdate = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = " " + d + "th" + " " + month[parseInt(m) - 1];

        return time;
    }
    const convertdatewithoutMonth = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = d + "th" + " ";

        return time;
    }
    return (
        <section className="mt-5 pt-5 pb-5 buynowbackground">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12  col-lg-8 leftscroll">

                        <div className="logincard">
                            {window.innerWidth < 780 ? <div className="productcard">
                                {props.cartdata.map((data, index) => {
                                    const isVisible = showarr.includes(index);
                                    return (
                                        <div className={isVisible ? "productbuynowcard" : "productbuynowcard hidden"} key={index}>
                                            <img src={data.productimage} className="img-fluid" alt={index} style={{
                                                            cursor: "pointer"
                                                        }} onClick={() => {
                                                            navigateToProduct(data.productpath)
                                                        }}/>
                                            <div className="productbuynowcardbody">
                                                <div className="cartproducttitle">{data.productname}</div>
                                                {/* <div className="cartproductcolor"><span style={{ color: "#8A8A8A" }}>Color:</span> {data.productcolor}</div> */}
                                                <div className="fan-specs">
                                                    <div className="cartproductcolor">{data.productcolor}</div>
                                                    <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                                </div>
                                                <div className="cartproductquantity">
                                                    <div className="cartproductquantitytitle"></div>
                                                    &nbsp;
                                                    <ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid} />
                                                    {/* <input id={`quantity${index}`}
                                                        type="number"
                                                        className="helixselect cartproductquantityselect" style={{ padding: "2px 2px", marginTop: "0px" }}
                                                        value={data.productquantity}
                                                        onChange={(e) => {
                                                            const inputValue = parseInt(e.target.value, 10);

                                                            if (inputValue < 0 || inputValue > 99) {
                                                                // If the input is invalid, you can choose to handle it here.
                                                                // For example, you might want to show an error message or prevent further processing.
                                                                return;
                                                            }
                                                            handleChangeQuantity(data.productid, e.target.value)

                                                        }}
                                                    /> */}

                                                    <div className="cartproductremove" onClick={() => {
                                                        removeItem(data.productid)
                                                    }}>Remove</div>

                                                    <div className="cartproductprice">Rs.{data.productdiscountedprice}</div>


                                                </div>
                                                {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}

                                            </div>

                                        </div>
                                    );
                                })}
                                {props.cartdata.length > 1 ? <div className="productshowmore" onClick={toggleShowMore}>
                                    {showMore ? "Show Less" : "Show More"} &nbsp; &nbsp;
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                                </div> : ""}
                            </div> : ""}
                            <div className="logincardhead cardheadactive deliverystart">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><span style={{ height: "27px" }}>1</span> &nbsp;&nbsp;&nbsp;
                                    <div style={{ marginTop: "5px" }}><h2>Login/Signup</h2>
                                        <h6 className="deliverynumber">{window.localStorage.getItem("phoneNumber") !== null ? <> {window.localStorage.getItem("phoneNumber").slice(3, 13)}</> : ""}</h6></div>
                                </div>
                                <NavLink to="/product_buy_now_login" className="buynowdecoration"><button className="btn btnchange">Change</button></NavLink>
                            </div>

                            <br />
                            <div className="logincardhead cardheadactive deliverystart">

                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><span style={{ height: "27px" }}>2</span> &nbsp;&nbsp;&nbsp;
                                    <div style={{ marginTop: "5px" }}><h2>Billing/Delivery Address</h2>
                                        {addressdata.length > 0 ? <h6 className="deliverynumber" style={{ display: "flex" }}>{addressdata[activeaddress].Address} {addressdata[activeaddress].Address1}, {addressdata[activeaddress].City}, {addressdata[activeaddress].State}, {addressdata[activeaddress].Country}, ({addressdata[activeaddress].Pincode})&nbsp;</h6> : ""}
                                        {/* <b>{addressdata[activeaddress].Phone.slice(3, 13)}</b>  */}
                                        {/* {addressdata[activeaddress].FirstName} {addressdata[activeaddress].LastName}, */}
                                    </div>
                                </div>
                                <NavLink to="/product_buy_now_delivery" className="buynowdecoration"> <button className="btn btnchange">Change</button></NavLink>
                            </div>

                            <br />
                            <NavLink to="/product_buy_now_order_summary" className="buynowdecoration">
                                <div className="logincardhead ">
                                    <span>3</span> &nbsp;&nbsp;&nbsp;
                                    <h2>Detailed Order Summary</h2>
                                </div>
                            </NavLink>
                            <div className="logincardbody orderShow" style={window.innerWidth < 780 ? { padding: "10px 0px 1px 0px" } : null}>
                                {props.cartdata.map((data, index) => {
                                    const isVisible = showarr1.includes(index);
                                    return (
                                        <>
                                            {window.innerWidth < 780
                                                ? <div className={isVisible ? "productbuynowcard " : "productbuynowcard hidden "} style={{ boxShadow: "none", alignItems: "flex-start" }} key={index}>
                                                    <div><img src={data.productimage} className="img-fluid" alt={index} style={{
                                                            cursor: "pointer"
                                                        }} onClick={() => {
                                                            navigateToProduct(data.productpath)
                                                        }}/>
                                                        <div className="cartproductprice">Rs.{data.productdiscountedprice}</div></div>
                                                    <div className="productbuynowcardbody">
                                                        <div className="cartproducttitle">{data.productname}</div>
                                                        {/* <div className="cartproductcolor"><span style={{ color: "#8A8A8A" }}>Color:</span> {data.productcolor}</div> */}
                                                        <div className="fan-specs">
                                                            <div className="cartproductcolor">{data.productcolor}</div>
                                                            <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                                        </div>
                                                        <div className="cartproductquantity">
                                                            <div className="cartproductquantitytitle"></div>
                                                            &nbsp;
                                                            <ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid} />


                                                            <div className="cartproductremove" onClick={() => {
                                                                removeItem(data.productid)
                                                            }}>Remove</div>




                                                        </div>
                                                        {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}

                                                        <div className=" ordercardbodydelivery mt-2">
                                                            <h3>Delivery by {convertdatewithoutMonth(moment().add(4, 'days').format("DD-MM-YYYY"))}-{convertdate(moment().add(7, 'days').format("DD-MM-YYYY"))}</h3>
                                                            {/* <h6>Free &nbsp; <span>Rs.{shippingPrice}</span></h6>
                                                <br /> */}

                                                            <h2 className="loginheadtext"> <input className="form-check-input" type="checkbox" style={{ marginTop: "-0.01rem" }} id={`flexCheckDefault${index}`} value={data.extendedwarranty} defaultChecked={data.extendedwarranty} onClick={(e) => {
                                                                handlewarranty(data.productid, e.target.value)
                                                            }} /> &nbsp; &nbsp; 2+2 Extended Warranty</h2>
                                                            {window.innerWidth < 780 ? <p className="loginheadtext">* Register within 30 days of purchase to get additional 2 years of warranty.</p> : ""}
                                                        </div>
                                                    </div>



                                                </div> : <div className={isVisible ? "row ordercardbody" : "row ordercardbody hidden"} key={index}>
                                                    <div className="col-md-3 ordercardbodyimg">
                                                        <img src={data.productimage} className="img-fluid" alt={index} style={{
                                                            cursor: "pointer"
                                                        }} onClick={() => {
                                                            navigateToProduct(data.productpath)
                                                        }} />
                                                        <div className="ordercardbodyprice">
                                                            Rs.{data.productdiscountedprice}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 ordercardbodytitle">
                                                        <h1>{data.productname}</h1>
                                                        {window.innerWidth > 768 ? <><h3>Color: {data.productcolor}</h3>
                                                            <div style={{ display: "flex", alignItems: "center", marginTop: "0.1rem" }}>
                                                                {/* <div style={{ display: "flex", alignItems: "center" }}>
                                                        <h3 className="remotename" style={{ marginBottom: "0px" }}>Remote:</h3>
                                                        <button className={"helixbuttonlist helixbuttonactive helix-remote  "}  >Yes</button>
                                                    </div>
                                                    &nbsp;&nbsp; */}
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    {/* <h3>Sweep Size: {data.productsweepsize}</h3> */}
                                                                    <span className="remotename">Sweep Size:</span> &nbsp;
                                                                    <button className={"buyordersweep"} >{data.productsweepsize}</button>
                                                                </div>

                                                            </div></> : <div className="productbuynowcardbody" style={{ paddingLeft: "0rem" }}>
                                                            <div className="fan-specs">
                                                                <div className="cartproductcolor">{data.productcolor}</div>
                                                                <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                                            </div>
                                                        </div>}
                                                        <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                                                            <div className="cartproductquantitytitle">Quantity:</div>
                                                            &nbsp;&nbsp;&nbsp;<ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid} />




                                                            <div className="cartproductremove" onClick={() => {
                                                                removeItem(data.productid)
                                                            }}>Remove</div>

                                                        </div> <br />

                                                        {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}
                                                    </div>
                                                    <div className="col-md-3 ordercardbodydelivery">
                                                        <h3>Delivery by {convertdatewithoutMonth(moment().add(4, 'days').format("DD-MM-YYYY"))}-{convertdate(moment().add(7, 'days').format("DD-MM-YYYY"))}</h3>
                                                        {/* <h6>Free &nbsp; <span>Rs.{shippingPrice}</span></h6>
                                                <br /> */}

                                                        <h2 className="loginheadtext"> <input className="form-check-input" type="checkbox" style={{ marginTop: "-0.01rem" }} id={`flexCheckDefault${index}`} value={data.extendedwarranty} defaultChecked={data.extendedwarranty} onClick={(e) => {
                                                            handlewarranty(data.productid, e.target.value)
                                                        }} /> &nbsp; &nbsp; 2+2 Extended Warranty</h2>
                                                        {window.innerWidth < 780 ? <p className="loginheadtext">* Register within 30 days of purchase to get additional 2 years of warranty.</p> : ""}
                                                    </div>

                                                </div>}
                                        </>

                                    );
                                })}



                            </div>
                            {props.cartdata.length > 1 ? <div className="productshowmore" onClick={toggleShowMore1}>
                                {showMore1 ? "Show Less" : "Show More"} &nbsp; &nbsp;
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore1 ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                            </div> : ""}

                            <div className="ordergst gst-input mt-3">
                                <div className="form-check ">
                                    <input className="form-check-input" type="checkbox" id="flexCheckDefaultgst" checked={gstenabled} value={gstenabled} onChange={() => {
                                        setgstenabled(!gstenabled);
                                        setgstno("");
                                        setgstname("");
                                        window.localStorage.removeItem("OrderGst")
                                        window.localStorage.removeItem("OrderGstBussinessName")

                                    }} />
                                    <label className="form-check-label labeltitle" htmlFor="flexCheckDefaultgst">
                                        Use GST Invoice (optional)
                                    </label>
                                </div>
                            </div>
                            <div className="gst-flex">
                                {gstenabled ? <>

                                    <input type="text" placeholder="GSTIN" className="formcontrol gstinput mt-3" value={gstno} onChange={(e) => {
                                        setgstno(e.target.value)
                                    }} />
                                    <input type="text" placeholder="Bussiness Name" className="formcontrol gstinput mt-3" value={gstname} onChange={(e) => {
                                        setgstname(e.target.value)
                                    }} /></> : ""}
                            </div>

                            <div className="ordergst mt-3">

                                <div className="row">
                                    <div className="col-md-12 col-lg-3">
                                        <div className="confirmgstorder">
                                            <span>Email ID<span style={{ color: "red" }} >*</span></span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-9">
                                        <input id="buyorderemail"  type="email" className="form-control buy-order" placeholder="Enter Your Email" style={{ border: "none" }} value={addemail} onChange={(e) => {
                                            setaddemail(e.target.value)
                                        }} />
                                    </div>
                                    <div className="col-md-12 col-lg-12 cnfrmphone cnfrm-tab" >
                                        {Subtotal-coupanprice>0?<button className="btn btnconfirmgst" onClick={() => {
                                            confirmemail()
                                        }}>
                                            Proceed
                                        </button>:""}
                                    </div>
                                </div>



                            </div>
                            {window.innerWidth < 780 ? <div className="coupanproductcard">
                                <div className="coupaninput">
                                    <input type="text" className="form-control" id="exampleInputcoupan1" onClick={() => {
                                        setLgShow1(true)
                                    }} placeholder="Coupon" style={{
                                        textTransform: "uppercase"
                                    }} value={coupancode} readOnly />
                                    <button className="btn btncoupan" onClick={() => {
                                        setLgShow1(true)
                                    }}>VIEW</button>
                                </div>

                                <div className="coupanproductcardbody" style={{ borderTop: "2px solid #DDDDDD", marginTop: "1rem" }}>
                                    <span className="productcardbodytitle">Subtotal</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal / 1.18).toFixed(2)}</span>
                                </div>
                                {shippingPrice > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Shipping</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {shippingPrice}</span></span>
                                </div> : ""}
                                {extendedwarranty > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Extended Warranty</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {totalextendedwarranty}</span></span>
                                </div> : ""}

                                {coupanprice > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Coupon Discount</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">-Rs. {coupanprice}</span></span>
                                </div> : ""}
                                {addressdata?.[activeaddress]?.State === "Uttar Pradesh" ? <div className="coupanproductcardbody">

                                    <span className="productcardbodytitle">Tax (CGST 9%, SGST 9%)</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                                </div> : <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Tax (IGST 18%)</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                                </div>}

                                <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle productamount" style={{ color: "#242933", }}>Amount Payable <small> (incl. tax)</small> </span>
                                    <span className="productcardbodyshipping"><span className="productgrandtotal productamountprice ">Rs. {Subtotal - coupanprice}</span></span>
                                </div>

                            </div> : ""}


                            <div className="logincardhead cardheadactive mt-3  mb-3">
                                <span>4</span> &nbsp;&nbsp;&nbsp;
                                <h2>Payment Options</h2>
                            </div>


                            {/* {window.innerWidth > 780 ? <div className="ordergst mt-3 p-4">
                                <h4>2+2 Years Extended Warranty</h4>
                                <p>* Register within 30 days of purchase to get additional 2 years of warranty.</p>
                            </div> : ""} */}
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 ">
                        {window.innerWidth > 780 ? <div className="productcard">
                            {props.cartdata.map((data, index) => {
                                const isVisible = showarr.includes(index);
                                return (
                                    <div className={isVisible ? "productbuynowcard" : "productbuynowcard hidden"} key={index}>
                                        <img src={data.productimage} className="img-fluid" alt={index} style={{
                                                            cursor: "pointer"
                                                        }} onClick={() => {
                                                            navigateToProduct(data.productpath)
                                                        }}/>
                                        <div className="productbuynowcardbody">
                                            <div className="cartproducttitle">{data.productname}</div>
                                            {/* <div className="cartproductcolor"><span style={{ color: "#8A8A8A" }}>Color:</span> {data.productcolor}</div> */}
                                            <div className="fan-specs">
                                                <div className="cartproductcolor">{data.productcolor}</div>
                                                <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                            </div>
                                            <div className="cartproductquantity">
                                                <div className="cartproductquantitytitle"></div>
                                                &nbsp;
                                                <ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid} />


                                                <div className="cartproductremove" onClick={() => {
                                                    removeItem(data.productid)
                                                }}>Remove</div>

                                                <div className="cartproductprice">Rs.{data.productdiscountedprice}</div>


                                            </div>
                                            {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}

                                        </div>

                                    </div>
                                );
                            })}
                            {props.cartdata.length > 1 ? <div className="productshowmore" onClick={toggleShowMore}>
                                {showMore ? "Show Less" : "Show More"} &nbsp; &nbsp;
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                            </div> : ""}
                        </div> : ""}

                        {window.innerWidth > 780 ? <div className="coupanproductcard">
                            <div className="coupaninput">
                                <input type="text" className="form-control" id="exampleInputcoupan1" onClick={() => {
                                    setLgShow1(true)
                                }} placeholder="Coupon" style={{
                                    textTransform: "uppercase"
                                }} value={coupancode} readOnly />
                                <button className="btn btncoupan" onClick={() => {
                                    setLgShow1(true)
                                }}>VIEW</button>
                            </div>

                            <div className="coupanproductcardbody" style={{ borderTop: "2px solid #DDDDDD", marginTop: "1rem" }}>
                                <span className="productcardbodytitle">Subtotal</span>
                                <span className="productcardbodydata">Rs. {(Subtotal / 1.18).toFixed(2)}</span>
                            </div>
                            {shippingPrice > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Shipping</span>
                                <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {shippingPrice}</span></span>
                            </div> : ""}
                            {extendedwarranty > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Extended Warranty</span>
                                <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {totalextendedwarranty}</span></span>
                            </div> : ""}

                            {coupanprice > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Coupon Discount</span>
                                <span className="productcardbodyshipping"><span className="productshipping">-Rs. {coupanprice}</span></span>
                            </div> : ""}
                            {addressdata?.[activeaddress]?.State === "Uttar Pradesh" ? <div className="coupanproductcardbody">

                                <span className="productcardbodytitle">Tax (CGST 9%, SGST 9%)</span>
                                <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                            </div> : <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Tax (IGST 18%)</span>
                                <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                            </div>}

                            <div className="coupanproductcardbody">
                                <span className="productcardbodytitle productamount" style={{ color: "#242933", }}>Amount Payable <small> (incl. tax)</small> </span>
                                <span className="productcardbodyshipping"><span className="productgrandtotal productamountprice ">Rs. {Subtotal - coupanprice}</span></span>
                            </div>

                        </div>

                            : ""}

                        <div className="productsafety mt-3">
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/secure.svg" className="img-fluid" alt="secure" />
                                <div className="productsafetydatatext">
                                    Secure <p>Payment</p>
                                </div>
                            </div>
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/guaranted.svg" className="img-fluid" alt="guaranted" />
                                <div className="productsafetydatatext">
                                    Satisfaction<p> Guaranteed</p>
                                </div>
                            </div>
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/privacybuy.svg" className="img-fluid" alt="privacy" />
                                <div className="productsafetydatatext">
                                    Your privacy <p>is priority</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"

                centered
                show={lgShow1}

                onHide={() => setLgShow1(false)}

            >
                <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>
                    <span className="coupantitle">COUPONS</span>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: "0rem" }}>


                    <div className="box-profile-pop-profile">





                        <div className="coupaninput p-3 pt-4">
                            <input type="text" className="form-control" id="exampleInputcoupan1" placeholder="Coupon" style={{
                                textTransform: "uppercase"
                            }} value={coupancode} onChange={(e) => {
                                setcoupancode(e.target.value)

                            }} />
                            {coupanActive ? <button className="btn btncoupan" style={{
                                color: "white",
                                backgroundColor: "#CB0000"
                            }} onClick={() => {
                                setcoupancode("");
                                setcoupanprice(0);
                                window.localStorage.removeItem("coupanprice");
                                window.localStorage.removeItem("coupancode");
                                setLgShow1(false);
                                setCoupanActive(false)
                            }}>Remove</button> :
                                <button className="btn btncoupan" onClick={() => {
                                    applyCoupan("")
                                }}>APPLY</button>}

                        </div>
                        <div className="coupancodebody">
                            {coupanlist.length > 0 && coupanlist.map((data, index) => {
                                return (
                                    <div className="coupanbody" key={index}>


                                        <div>

                                            <p className="coupancode">{data.coupon_code}</p>
                                            <p className="coupandescription">{data.coupon_description}</p>
                                        </div>
                                        <div className="coupanapply">
                                            <button className="btn btncoupanapply" onClick={() => {
                                                setcoupancode(data.coupon_code)
                                                applyCoupan(data.coupon_code)
                                            }}>APPLY</button>
                                        </div>


                                    </div>
                                )
                            })
                            }

                        </div>









                    </div>


                </Modal.Body>
            </Modal>
        </section>
    );
}
export default Buynoworder;