import "./Buynowpayment.css"
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import { NavLink, useNavigate } from "react-router-dom";
import ProductQuantitySelect from "../Shared/QuantityModule";
function Buynowpayment(props) {
    const [Subtotal, setSubtotal] = useState(0);
    const [addressdata, setaddressdata] = useState([])
    const [activeaddress, setactiveaddress] = useState(0)
    const [extendedwarranty, setextendedwarranty] = useState(0)
    const [paymentmethod, setpaymentmethod] = useState("online")
    const [shippingPrice, setShippingPrice] = useState(0)
    const [placeOrderButton, setPlaceOrderButton] = useState(false)
    const [totalextendedwarranty, settotalextendedwarranty] = useState(0)
    const [quantity, setQuantity] = useState(0)
    useEffect(() => {


        var sum = 0;
        var quantity = 0;
        for (let i = 0; i < props.cartdata.length; i++) {
            sum = sum + Number(props.cartdata[i].productdiscountedprice)
            quantity = quantity + Number(props.cartdata[i].productquantity)

        }
        setQuantity(quantity)
        if (props.cartdata.length === 0) {
            localStorage.removeItem("activeaddress")
        }
        var extendedwarrantysum = 0
        var totalextendedwarrantysum = 0
        var shippingtotalprice = 0
        for (let i = 0; i < props.cartdata.length; i++) {
            if (props.cartdata[i].extendedwarranty === true) {
                sum = sum + 0
                extendedwarrantysum = (extendedwarrantysum + 199) * Number(props.cartdata[i].productquantity)
                totalextendedwarrantysum = (totalextendedwarrantysum + 499) * Number(props.cartdata[i].productquantity)
                shippingtotalprice = (shippingtotalprice + 100) * Number(props.cartdata[i].productquantity)


            }

        }
        setShippingPrice(shippingtotalprice)
        settotalextendedwarranty(totalextendedwarrantysum)
        const coupandata = coupanlist.filter((data) => {
            return data.coupon_code === coupancode.toUpperCase()
        })

        if (coupandata.length > 0) {
            if (coupandata[0].discount_type === "Rs.") {
                if (sum < Number(coupandata[0].min_cart_value)) {
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setCoupanActive(false)
                }
            } else {
                if (sum < Number(coupandata[0].min_cart_value)) {
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setCoupanActive(false)
                }
            }
        }
        setSubtotal(sum)

        setextendedwarranty(extendedwarrantysum)
        if (window.localStorage.getItem("Address")) {

            setaddressdata(JSON.parse(window.localStorage.getItem("Address")))
        }
        if (window.localStorage.getItem("activeaddress")) {
            setactiveaddress(window.localStorage.getItem("activeaddress"))
        }


    }, [props])

    const navigate = useNavigate()
    const removeItem = (productId) => {
        props.setcartdata(prevData => {
            const updatedData = prevData.filter(item => item.productid !== productId);
            window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
            return updatedData;
        });

        if (props.cartdata.length === 1) {
            localStorage.removeItem("activeaddress")
            navigate('/bldc-ceiling-fan');
        }
    };
    useEffect(() => {
        if (window.localStorage.getItem("cartdata") !== null) {
            if (JSON.parse(window.localStorage.getItem("cartdata")).length === 0) {
                navigate("/bldc-ceiling-fan")
            }
        } else {
            navigate("/bldc-ceiling-fan")
        }
    }, [])

    const handleChangeQuantity = (productId, newQuantity) => {
        if (newQuantity === "" || newQuantity === 0) {
            toast.error("Please enter a valid Quantity"); // Display an error toast notification
            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {
                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        } else {
            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {
                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        }

        if(window.localStorage.getItem("coupancode") !== null){
            applyCoupan(window.localStorage.getItem("coupancode"))
        }
    };
    const [showMore, setShowMore] = useState(false);
    const [showarr, setShowArr] = useState([0]);

    const toggleShowMore = () => {
        setShowMore(!showMore);
        if (showMore) {
            setShowArr([0]);
        } else {
            setShowArr(props.cartdata.map((_, index) => index));
        }
    };
    const placeorder = async () => {

        for (let i = 0; i < props.cartdata.length; i++) {
            if (parseInt(props.cartdata[i].productquantity) > parseInt(props.cartdata[i].inventory)) {
                toast.error(`Please check product ${i + 1} is out of stock`); // Display an error toast notification
                return
            }
        }
        var data = JSON.parse(window.localStorage.getItem("cartdata"))
        var address = JSON.parse(window.localStorage.getItem("Address"))
        if (address.length > 1) {
            address = address[parseInt(window.localStorage.getItem("activeaddress"))]
        } else {
            address = address[0]
        }
        var maindata = {
            "OrderId": "",
            "UserId": window.localStorage.getItem("UserId"),
            "OrderDate": moment().format('DD-MM-YYYY HH:mm:ss'),
            "OrderStatus": "Order Confirmed",
            "OrderEmail": window.localStorage.getItem("addorderemail"),
            "OrderDetail": [],
            "OrderAddress": [address],
            "OrderGST": window.localStorage.getItem("OrderGst") !== null ? window.localStorage.getItem("OrderGst") : "NA",
            "OrderGSTBussinessName": window.localStorage.getItem("OrderGstBussinessName") !== null ? window.localStorage.getItem("OrderGstBussinessName") : "NA",
            "OrderTransactionId": "dummy" + Math.floor(Math.random() * 1000000000000000000),
            "OrderPaymentType": "COD",
            "OrderCoupanPrice": coupanprice,
            "OrderCoupanId": window.localStorage.getItem("coupancode") !== null ? window.localStorage.getItem("coupancode") : "",
            "UserPhone": window.localStorage.getItem("phoneNumber"),
            "SubTotal": Subtotal,
        }

        var orders = []
        for (let i = 0; i < data.length; i++) {
            if (parseInt(data[i].productquantity) > 0) {
                orders.push({
                    "OrderItemId": data[i].productid,
                    "inventory": data[i].inventory,
                    "OrderTotal": data[i].productprice,
                    "OrderSubTotal": data[i].productdiscountedprice,
                    "OrderQuantity": data[i].productquantity,
                    "OrderColor": data[i].productcolor,
                    "OrderSize": data[i].productsweepsize,
                    "OrderImage": data[i].productimage,
                    "OrderName": data[i].productname,
                    "OrderExtendedWarranty": data[i].extendedwarranty,
                    "ProductPath": data[i].productpath,
                    "modal": data[i].modal,
                })
            } else {
                toast.error("Please enter a valid Quantity"); // Display an error toast notification
            }

        }
        maindata.OrderDetail = orders
        if (maindata.OrderDetail.length === data.length) {
            setPlaceOrderButton(true)
            try {
                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotCreateOrders`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                    },

                    body: JSON.stringify({ maindata }),
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {

                        window.localStorage.removeItem("cartdata")

                        window.localStorage.removeItem("activeaddress")
                        window.localStorage.removeItem("addorderemail")
                        window.localStorage.removeItem("OrderGst")
                        window.localStorage.removeItem("OrderGstBussinessName")
                        window.localStorage.removeItem("coupanprice")
                        window.localStorage.removeItem("coupancode")

                        props.setshoworderpop(true)
                        navigate("/thank-you")
                        window.localStorage.setItem('showorderpop', true)

                        props.setcartdata([])
                        setPlaceOrderButton(false)








                        toast.success(response.message);
                    } else {

                        toast.error(response.message);
                        setPlaceOrderButton(false)
                    }
                })
            } catch (error) {
                console.error('API Error:', error);
                toast.error(error);
                setPlaceOrderButton(false)
            }
        }

    }

    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "INR",

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const loadScript = (src) => {
        return new Promise((resovle) => {
            const script = document.createElement("script");
            script.src = src;

            script.onload = () => {
                resovle(true);
            };

            script.onerror = () => {
                resovle(false);
            };

            document.body.appendChild(script);
        });
    };

    const displayRazorpay = async () => {
        for (let i = 0; i < props.cartdata.length; i++) {
            if (parseInt(props.cartdata[i].productquantity) > parseInt(props.cartdata[i].inventory)) {
                toast.error(`Please check product ${i + 1} is out of stock`); // Display an error toast notification
                return
            }
        }

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("You are offline... Failed to load Razorpay SDK");
            return;
        }

        const options = {
            key: process.env.REACT_APP_PAYMENT_KEY,
            currency: "INR",
            amount: Number(Subtotal) * 100 - coupanprice * 100,
            name: "Colorbot",
            description: "Thanks for purchasing",
            image:
                "https://colorbots3.s3.ap-south-1.amazonaws.com/web-icon.svg",

            handler: async function (response) {
                await placeOnline(response.razorpay_payment_id)

            },
            prefill: {
                name: "Colorbot",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };
    const placeOnline = async (transactionid) => {
        for (let i = 0; i < props.cartdata.length; i++) {
            if (parseInt(props.cartdata[i].productquantity) > parseInt(props.cartdata[i].inventory)) {
                toast.error(`Please check product ${i + 1} is out of stock`); // Display an error toast notification
                return
            }
        }
        var data = JSON.parse(window.localStorage.getItem("cartdata"))
        var address = JSON.parse(window.localStorage.getItem("Address"))
        if (address.length > 1) {
            address = address[parseInt(window.localStorage.getItem("activeaddress"))]
        } else {
            address = address[0]
        }
        var maindata = {
            "OrderId": "",
            "UserId": window.localStorage.getItem("UserId"),
            "OrderDate": moment().format('DD-MM-YYYY HH:mm:ss'),
            "OrderStatus": "Order Confirmed",
            "OrderEmail": window.localStorage.getItem("addorderemail"),
            "OrderDetail": [],
            "OrderAddress": [address],
            "OrderGST": window.localStorage.getItem("OrderGst") !== null ? window.localStorage.getItem("OrderGst") : "NA",
            "OrderGSTBussinessName": window.localStorage.getItem("OrderGstBussinessName") !== null ? window.localStorage.getItem("OrderGstBussinessName") : "NA",
            "OrderTransactionId": transactionid,
            "OrderPaymentType": "Prepaid",
            "OrderCoupanPrice": coupanprice,
            "OrderCoupanId": window.localStorage.getItem("coupancode") !== null ? window.localStorage.getItem("coupancode") : "",
            "UserPhone": window.localStorage.getItem("phoneNumber"),
            "Subtotal": Subtotal,
        }

        var orders = []
        for (let i = 0; i < data.length; i++) {
            if (parseInt(data[i].productquantity) > 0) {
                orders.push({
                    "OrderItemId": data[i].productid,
                    "OrderTotal": data[i].productprice,
                    "OrderSubTotal": data[i].productdiscountedprice,
                    "OrderQuantity": data[i].productquantity,
                    "inventory": data[i].inventory,
                    "OrderColor": data[i].productcolor,
                    "OrderSize": data[i].productsweepsize,
                    "OrderImage": data[i].productimage,
                    "OrderName": data[i].productname,
                    "OrderExtendedWarranty": data[i].extendedwarranty,
                    "ProductPath": data[i].productpath,
                    "modal": data[i].modal,
                })
            } else {
                toast.error("Please enter a valid Quantity"); // Display an error toast notification
            }

        }
        maindata.OrderDetail = orders
        if (maindata.OrderDetail.length === data.length) {
            setPlaceOrderButton(true)
            try {
                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotCreateOrders`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                    },

                    body: JSON.stringify({ maindata }),
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {

                        window.localStorage.removeItem("cartdata")

                        window.localStorage.removeItem("activeaddress")
                        window.localStorage.removeItem("addorderemail")
                        window.localStorage.removeItem("OrderGst")
                        window.localStorage.removeItem("OrderGstBussinessName")
                        window.localStorage.removeItem("coupanprice")
                        window.localStorage.removeItem("coupancode")

                        props.setshoworderpop(true)
                        navigate("/thank-you")
                        window.localStorage.setItem('showorderpop', true)

                        props.setcartdata([])
                        setPlaceOrderButton(false)








                        setTimeout(() => {
                            toast.success(response.message);
                        }, 3000);
                    } else {

                        toast.error(response.message);
                        setPlaceOrderButton(false)
                    }
                })
            } catch (error) {
                console.error('API Error:', error);
                toast.error(error);
                setPlaceOrderButton(false)
            }
        }
    }
    // useEffect(() => {

    //     window.scrollTo(0, 0, {
    //         behavior: 'smooth'
    //     })


    // }, [])

    useEffect(() => {
        document.title = "Payment - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Payment - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    const [lgShow1, setLgShow1] = useState(false);

    const [coupanlist, setcoupanlist] = useState([])
    const [coupancode, setcoupancode] = useState("")
    const [coupanprice, setcoupanprice] = useState(0)
    const [userlist, setUserList] = useState([])
    const getUser = async () => {
        try {

            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetUserCoupan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
                body: JSON.stringify({ UserId: window.localStorage.getItem("UserId") })

            });

            if (response.status === 200) {
                const data = await response.json();
                setUserList(data.data)
                getCoupan()

            }
        } catch (error) {
            console.error('API Error:', error);

        }
    }
    const getCoupan = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetWebCoupan`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                let filterdata = []
                for (let i = 0; i < data.data.length; i++) {


                    filterdata.push(data.data[i])




                }
console.log(window.localStorage.getItem("coupancode"))
                for (let i = 0; i < userlist.length; i++) {
                    for (let j = 0; j < filterdata.length; j++) {
                        if (userlist[i].coupanid === filterdata[j].coupon_code && filterdata[j].gift === "first") {
                            console.log(window.localStorage.getItem("coupancode") === filterdata[j].coupon_code)
                            if(window.localStorage.getItem("coupancode") === filterdata[j].coupon_code){
                                setcoupanprice(0)
                                window.localStorage.removeItem("coupanprice")
                                window.localStorage.removeItem("coupancode")
                            }
                            filterdata.splice(j, 1)
                        }
                        if (userlist[i].coupanid === filterdata[j].coupon_code && filterdata[j].gift === "gift") {
                            if(window.localStorage.getItem("coupancode") === filterdata[j].coupon_code){
                                setcoupanprice(0)
                                window.localStorage.removeItem("coupanprice")
                                window.localStorage.removeItem("coupancode")
                            }
                            filterdata.splice(j, 1)
                        }
                    }
                }

                var filterdata1 = []
                for (let i = 0; i < filterdata.length; i++) {
                    if (Number(filterdata[i].noOfUsers) > 0 || filterdata[i].noOfUsers === undefined || filterdata[i].noOfUsers === "") {
                        filterdata1.push(filterdata[i])
                    }

                }


                setcoupanlist(filterdata1)

            }
        } catch (error) {
            console.error('API Error:', error);

        }
    }
    const applyCoupan = async (code) => {
        if (code === "") {
            const data = coupanlist.filter((data) => {
                return data.coupon_code === coupancode.toUpperCase()
            })
            if (data.length > 0) {
                if (parseInt(Subtotal) > parseInt(data[0].discount)) {
                    if (data[0].discount_type === "Rs.") {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice(data[0].discount)
                            window.localStorage.setItem("coupanprice", parseInt(data[0].discount))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                            setCoupanActive(true)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    } else {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice((Subtotal * data[0].discount) / 100)
                            window.localStorage.setItem("coupanprice", ((Subtotal * parseInt(data[0].discount)) / 100))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    }
                } else {
                    toast.error("Amount should be greater than " + data[0].discount)
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setLgShow1(false);
                    setCoupanActive(false)
                }
            } else {
                toast.error("Please enter a valid coupon code")
            }

        } else {
            const data = coupanlist.filter((data) => {
                return data.coupon_code === code
            })
            if (data.length > 0) {
                if (parseInt(Subtotal) > parseInt(data[0].discount)) {
                    if (data[0].discount_type === "Rs.") {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice(parseInt(data[0].discount))
                            window.localStorage.setItem("coupanprice", parseInt(data[0].discount))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    } else {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice((Subtotal * data[0].discount) / 100)
                            window.localStorage.setItem("coupanprice", ((Subtotal * parseInt(data[0].discount)) / 100))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    }
                } else {
                    toast.error("Amount should be greater than " + data[0].discount)
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setLgShow1(false);
                    setCoupanActive(false)
                }
            } else {
                toast.error("Please enter a valid coupon code")
            }

        }
    }
    const [coupanActive, setCoupanActive] = useState(false)
    useEffect(() => {
        if (window.localStorage.getItem("coupanprice")) {
            setcoupanprice(parseInt(window.localStorage.getItem("coupanprice")))
        }
        if (window.localStorage.getItem("coupancode")) {
            setcoupancode(window.localStorage.getItem("coupancode"))
            setCoupanActive(true)
        } else {

            setCoupanActive(false)
        }
        getUser()
    }, [lgShow1])
    const navigateToProduct = (path) => {
        navigate(path);
    }
    return (
        <section className="mt-5 pt-5 pb-5 buynowbackground">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12  col-lg-8">
                        <div className="logincard">
                            {window.innerWidth < 780 ? <div className="productcard">
                                {props.cartdata.map((data, index) => {
                                    const isVisible = showarr.includes(index);
                                    return (
                                        <div className={isVisible ? "productbuynowcard" : "productbuynowcard hidden"} key={index}>
                                            <img src={data.productimage} className="img-fluid" alt={index} style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                navigateToProduct(data.productpath)
                                            }} />
                                            <div className="productbuynowcardbody">
                                                <div className="cartproducttitle">{data.productname}</div>
                                                {/* <div className="cartproductcolor"><span style={{ color: "#8A8A8A" }}>Color:</span> {data.productcolor}</div> */}
                                                <div className="fan-specs">
                                                    <div className="cartproductcolor">{data.productcolor}</div>
                                                    <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                                </div>
                                                <div className="cartproductquantity">
                                                    <div className="cartproductquantitytitle"></div>
                                                    &nbsp;
                                                    <ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid} />
                                                    {/* <input id={`quantity${index}`}
                                                            type="number"
                                                            className="helixselect cartproductquantityselect" style={{ padding: "2px 2px", marginTop: "0px" }}
                                                            value={data.productquantity}
                                                            onChange={(e) => {
                                                                const inputValue = parseInt(e.target.value, 10);

                                                                if (inputValue < 0 || inputValue > 99) {
                                                                    // If the input is invalid, you can choose to handle it here.
                                                                    // For example, you might want to show an error message or prevent further processing.
                                                                    return;
                                                                }
                                                                handleChangeQuantity(data.productid, e.target.value)

                                                            }}
                                                        /> */}

                                                    <div className="cartproductremove" onClick={() => {
                                                        removeItem(data.productid)
                                                    }}>Remove</div>

                                                    <div className="cartproductprice">Rs.{data.productdiscountedprice}</div>


                                                </div>
                                                {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}

                                            </div>

                                        </div>
                                    );
                                })}
                                {props.cartdata.length > 1 ? <div className="productshowmore" onClick={toggleShowMore}>
                                    {showMore ? "Show Less" : "Show More"} &nbsp; &nbsp;
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                                </div> : ""}
                            </div> : ""}
                            <div className="logincardhead cardheadactive deliverystart">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><span style={{ height: "27px" }}>1</span> &nbsp;&nbsp;&nbsp;
                                    <div style={{ marginTop: "5px" }}><h2>Login/Signup</h2>
                                        <h6 className="deliverynumber">{window.localStorage.getItem("phoneNumber") !== null ? <> {window.localStorage.getItem("phoneNumber").slice(3, 13)}</> : ""}</h6></div>
                                </div>
                                <NavLink to="/product_buy_now_login" className="buynowdecoration"> <button className="btn btnchange">Change</button></NavLink>
                            </div>

                            <br />
                            <div className="logincardhead cardheadactive deliverystart">

                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><span style={{ height: "27px" }}>2</span> &nbsp;&nbsp;&nbsp;
                                    <div style={{ marginTop: "5px" }}><h2>Billing/Delivery Address</h2>
                                        {addressdata.length > 0 ? <h6 className="deliverynumber" style={{ display: "flex" }}>{addressdata[activeaddress].Address} {addressdata[activeaddress].Address1}, {addressdata[activeaddress].City}, {addressdata[activeaddress].State}, {addressdata[activeaddress].Country}, ({addressdata[activeaddress].Pincode})</h6> : ""}
                                    </div>
                                </div>
                                <NavLink to="/product_buy_now_delivery" className="buynowdecoration"> <button className="btn btnchange">Change</button></NavLink>
                            </div>

                            <br />

                            <div className="logincardhead  cardheadactive deliverystart">

                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><span style={{ height: "27px" }}>3</span> &nbsp;&nbsp;&nbsp;
                                    <div style={{ marginTop: "5px" }}><h2>Detailed Order Summary</h2>
                                        <h6 className="deliverynumber" style={{ display: "flex" }}>{props.cartdata.length} item</h6></div>
                                </div>
                                <NavLink to="/product_buy_now_order_summary" className="buynowdecoration">  <button className="btn btnchange">Change</button></NavLink>
                            </div>

                            {window.innerWidth < 780 ? <div className="coupanproductcard">
                                <div className="coupaninput">
                                    <input type="text" className="form-control" id="exampleInputcoupan1" onClick={() => {
                                        setLgShow1(true)
                                    }} placeholder="Coupon" style={{
                                        textTransform: "uppercase"
                                    }} value={coupancode} readOnly />
                                    <button className="btn btncoupan" onClick={() => {
                                        setLgShow1(true)
                                    }}>VIEW</button>
                                </div>

                                <div className="coupanproductcardbody" style={{ borderTop: "2px solid #DDDDDD", marginTop: "1rem" }}>
                                    <span className="productcardbodytitle">Subtotal</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal / 1.18).toFixed(2)}</span>
                                </div>
                                {shippingPrice > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Shipping</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {shippingPrice}</span></span>
                                </div> : ""}
                                {extendedwarranty > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Extended Warranty</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {totalextendedwarranty}</span></span>
                                </div> : ""}

                                {coupanprice > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Coupon Discount</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">-Rs. {coupanprice}</span></span>
                                </div> : ""}
                                {addressdata?.[activeaddress]?.State === "Uttar Pradesh" ? <div className="coupanproductcardbody">

                                    <span className="productcardbodytitle">Tax (CGST 9%, SGST 9%)</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                                </div> : <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Tax (IGST 18%)</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                                </div>}

                                <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle productamount" style={{ color: "#242933", }}>Amount Payable <small> (incl. tax)</small> </span>
                                    <span className="productcardbodyshipping"><span className="productgrandtotal productamountprice ">Rs. {Subtotal - coupanprice}</span></span>
                                </div>

                            </div> : ""}



                            <br />
                            <NavLink to="/product_buy_now_payment" className="buynowdecoration">
                                <div className="logincardhead ">
                                    <span>4</span> &nbsp;&nbsp;&nbsp;
                                    <h2>Payment Options</h2>
                                </div>
                            </NavLink>
                            <div className="logincardbody">
                                <div className="addressbox payment" >
                                    <div style={{ display: "flex", alignItems: "center" }}> <input className="form-check-input" type="radio" name={"flexRadioDefaultpayment"} id={"flexRadioDefaultonline"} checked={paymentmethod == "online" ? true : false} value={"online"} onClick={() => {
                                        setpaymentmethod("online")
                                    }} />
                                        &nbsp; &nbsp; <label htmlFor="flexRadioDefaultonline">  <h3 className="razorpay-img">Pay with &nbsp;&nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/razorpay-logo.png" className="img-fluid" alt="track" /></h3>
                                        </label></div>
                                    {paymentmethod == "online" && (Subtotal - coupanprice) > 0 ? <div className="addresscolumn paybtn">

                                        <button className="btn btnselectaddress" disabled={Subtotal === 0 || placeOrderButton ? true : false} onClick={() => {
                                            displayRazorpay()
                                        }}
                                        >Proceed to Pay Rs.{Subtotal - coupanprice}</button>
                                    </div> : ""}
                                </div>

                                <div className="addressbox payment" >
                                    <div style={{ display: "flex" }}> <input className="form-check-input" type="radio" defaultChecked name={"flexRadioDefaultpayment"} id={"flexRadioDefaultcash"} disabled={quantity < 3 && quantity > 0 ? false : true} checked={paymentmethod == "cash" && quantity < 3 && quantity > 0 ? true : false} value={"cash"} onClick={() => {
                                        quantity < 3 ? setpaymentmethod("cash") : setpaymentmethod("online")
                                    }} />
                                        &nbsp; &nbsp;  <label htmlFor="flexRadioDefaultcash"><h3 style={quantity < 3 && quantity > 0 ? { color: "#242933" } : { color: "lightgray" }}>Cash on Delivery (COD) </h3></label>
                                    </div>
                                    {paymentmethod == "cash" && quantity < 3 && quantity > 0 && (Subtotal - coupanprice) > 0 ? <div className="addresscolumn paybtn">

                                        <button className="btn btnselectaddress" disabled={Subtotal === 0 || quantity > 2 || placeOrderButton ? true : false} onClick={() => {
                                            placeorder()
                                        }}
                                        >Proceed to Pay</button>
                                    </div> : ""}

                                </div>



                            </div>

                        </div>
                    </div>
                    <div className="col-md-12  col-lg-4">
                        {window.innerWidth > 780 ? <div className="productcard">
                            {props.cartdata.map((data, index) => {
                                const isVisible = showarr.includes(index);
                                return (
                                    <div className={isVisible ? "productbuynowcard" : "productbuynowcard hidden"} key={index}>
                                        <img src={data.productimage} className="img-fluid" alt={index} style={{
                                            cursor: "pointer"
                                        }} onClick={() => {
                                            navigateToProduct(data.productpath)
                                        }} />
                                        <div className="productbuynowcardbody">
                                            <div className="cartproducttitle">{data.productname}</div>
                                            {/* <div className="cartproductcolor"><span style={{ color: "#8A8A8A" }}>Color:</span> {data.productcolor}</div> */}
                                            <div className="fan-specs">
                                                <div className="cartproductcolor">{data.productcolor}</div>
                                                <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                            </div>
                                            <div className="cartproductquantity">
                                                <div className="cartproductquantitytitle"></div>
                                                &nbsp;
                                                <ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid} />
                                                {/* <input id={`quantity${index}`}
                                                        type="number"
                                                        className="helixselect cartproductquantityselect" style={{ padding: "2px 2px", marginTop: "0px" }}
                                                        value={data.productquantity}
                                                        onChange={(e) => {
                                                            const inputValue = parseInt(e.target.value, 10);

                                                            if (inputValue < 0 || inputValue > 99) {
                                                                // If the input is invalid, you can choose to handle it here.
                                                                // For example, you might want to show an error message or prevent further processing.
                                                                return;
                                                            }
                                                            handleChangeQuantity(data.productid, e.target.value)

                                                        }}
                                                    /> */}

                                                <div className="cartproductremove" onClick={() => {
                                                    removeItem(data.productid)
                                                }}>Remove</div>

                                                <div className="cartproductprice">Rs.{data.productdiscountedprice}</div>


                                            </div>
                                            {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}

                                        </div>

                                    </div>
                                );
                            })}
                            {props.cartdata.length > 1 ? <div className="productshowmore" onClick={toggleShowMore}>
                                {showMore ? "Show Less" : "Show More"} &nbsp; &nbsp;
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                            </div> : ""}
                        </div> : ""}

                        {window.innerWidth > 780 ? <div className="coupanproductcard">
                            <div className="coupaninput">
                                <input type="text" className="form-control" id="exampleInputcoupan1" onClick={() => {
                                    setLgShow1(true)
                                }} placeholder="Coupon" style={{
                                    textTransform: "uppercase"
                                }} value={coupancode} readOnly />
                                <button className="btn btncoupan" onClick={() => {
                                    setLgShow1(true)
                                }}>VIEW</button>
                            </div>

                            <div className="coupanproductcardbody" style={{ borderTop: "2px solid #DDDDDD", marginTop: "1rem" }}>
                                <span className="productcardbodytitle">Subtotal</span>
                                <span className="productcardbodydata">Rs. {(Subtotal / 1.18).toFixed(2)}</span>
                            </div>
                            {shippingPrice > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Shipping</span>
                                <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {shippingPrice}</span></span>
                            </div> : ""}
                            {extendedwarranty > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Extended Warranty</span>
                                <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {totalextendedwarranty}</span></span>
                            </div> : ""}

                            {coupanprice > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Coupon Discount</span>
                                <span className="productcardbodyshipping"><span className="productshipping">-Rs. {coupanprice}</span></span>
                            </div> : ""}
                            {addressdata?.[activeaddress]?.State === "Uttar Pradesh" ? <div className="coupanproductcardbody">

                                <span className="productcardbodytitle">Tax (CGST 9%, SGST 9%)</span>
                                <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                            </div> : <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Tax (IGST 18%)</span>
                                <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                            </div>}

                            <div className="coupanproductcardbody">
                                <span className="productcardbodytitle productamount" style={{ color: "#242933", }}>Amount Payable <small> (incl. tax)</small> </span>
                                <span className="productcardbodyshipping"><span className="productgrandtotal productamountprice ">Rs. {Subtotal - coupanprice}</span></span>
                            </div>

                        </div> : ""}

                        <div className="productsafety mt-3">
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/secure.svg" className="img-fluid" alt="secure" />
                                <div className="productsafetydatatext">
                                    Secure <p>Payment</p>
                                </div>
                            </div>
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/guaranted.svg" className="img-fluid" alt="guaranted" />
                                <div className="productsafetydatatext">
                                    Satisfaction<p> Guaranteed</p>
                                </div>
                            </div>
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/privacybuy.svg" className="img-fluid" alt="privacy" />
                                <div className="productsafetydatatext">
                                    Your privacy <p>is priority</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"

                centered
                show={lgShow1}

                onHide={() => setLgShow1(false)}

            >
                <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>
                    <span className="coupantitle">COUPONS</span>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: "0rem" }}>


                    <div className="box-profile-pop-profile">
                        <div className="coupaninput p-3 pt-4">
                            <input type="text" className="form-control" id="exampleInputcoupan1" placeholder="Coupon" style={{
                                textTransform: "uppercase"
                            }} value={coupancode} onChange={(e) => {
                                setcoupancode(e.target.value)

                            }} />
                            {coupanActive ? <button className="btn btncoupan" style={{
                                color: "white",
                                backgroundColor: "#CB0000"
                            }} onClick={() => {
                                setcoupancode("");
                                setcoupanprice(0);
                                window.localStorage.removeItem("coupanprice");
                                window.localStorage.removeItem("coupancode");
                                setLgShow1(false);
                                setCoupanActive(false)
                            }}>Remove</button> :
                                <button className="btn btncoupan" onClick={() => {
                                    applyCoupan("")
                                }}>APPLY</button>}

                        </div>
                        <div className="coupancodebody">
                            {coupanlist.length > 0 && coupanlist.map((data, index) => {
                                return (
                                    <div className="coupanbody" key={index}>


                                        <div>

                                            <p className="coupancode">{data.coupon_code}</p>
                                            <p className="coupandescription">{data.coupon_description}</p>
                                        </div>
                                        <div className="coupanapply">
                                            <button className="btn btncoupanapply" onClick={() => {
                                                setcoupancode(data.coupon_code)
                                                applyCoupan(data.coupon_code)
                                            }}>APPLY</button>
                                        </div>


                                    </div>
                                )
                            })
                            }

                        </div>









                    </div>


                </Modal.Body>
            </Modal>
        </section>
    );

}
export default Buynowpayment;