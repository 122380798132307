import "./Buynowdelivery.css"
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import ProductQuantitySelect from "../Shared/QuantityModule";
function Buynowdelivery(props) {
    const [lgShow, setLgShow] = useState(false);
    const [Subtotal, setSubtotal] = useState(0);
    const [addressdata, setaddressdata] = useState([])
    const [activeaddress, setactiveaddress] = useState(null)

    const [Country, setCountry] = useState("India")
    const [State, setState] = useState("")
    const [City, setCity] = useState("")
    const [pincode, setPincode] = useState("")
    const [Address, setAddress] = useState("")
    const [Address1, setAddress1] = useState("")
    const [addfirst, setaddfirst] = useState("")
    const [addlast, setaddlast] = useState("")
    const [addphone, setaddphone] = useState("")

    const [addtype, setaddtype] = useState(false)
    const [error, setError] = useState("")
    const [editindex, seteditindex] = useState(null)

    const [lgShow1, setLgShow1] = useState(false);
    const [shippingPrice, setShippingPrice] = useState(0)
    const [extendedwarranty, setextendedwarranty] = useState(0)
    const [totalextendedwarranty, settotalextendedwarranty] = useState(0)
    useEffect(() => {


        var sum = 0;
        for (let i = 0; i < props.cartdata.length; i++) {
            sum = sum + Number(props.cartdata[i].productdiscountedprice)
        }
        if (props.cartdata.length === 0) {
            localStorage.removeItem("activeaddress")
        }
        var extendedwarrantysum = 0
        var totalextendedwarrantysum = 0
        var shippingtotalprice = 0
        for (let i = 0; i < props.cartdata.length; i++) {
            if (props.cartdata[i].extendedwarranty === true) {
                sum = sum + 0
                extendedwarrantysum = (extendedwarrantysum + 199) * Number(props.cartdata[i].productquantity)
                totalextendedwarrantysum = (totalextendedwarrantysum + 499) * Number(props.cartdata[i].productquantity)
                shippingtotalprice = (shippingtotalprice + 100) * Number(props.cartdata[i].productquantity)


            }

        }
        setShippingPrice(shippingtotalprice)
        settotalextendedwarranty(totalextendedwarrantysum)
        const coupandata = coupanlist.filter((data) => {
            return data.coupon_code === coupancode.toUpperCase()
        })

        if (coupandata.length > 0) {
            if (coupandata[0].discount_type === "Rs.") {
                if (sum < Number(coupandata[0].min_cart_value)) {
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setCoupanActive(false)
                }
            } else {
                if (sum < Number(coupandata[0].min_cart_value)) {
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setCoupanActive(false)
                }
            }
        }
        setSubtotal(sum)

        setextendedwarranty(extendedwarrantysum)
        if (window.localStorage.getItem("Address")) {
            if (JSON.parse(window.localStorage.getItem("Address")).length === 0) {
                setaddphone(window.localStorage.getItem("phoneNumber").slice(3, 13))
            }
            setaddressdata(JSON.parse(window.localStorage.getItem("Address")))
        } else {
            setaddphone(window.localStorage.getItem("phoneNumber").slice(3, 13))
        }

        var Address = window.localStorage.getItem("Address") === "" ? null : JSON.parse(window.localStorage.getItem("Address"))
        if (window.localStorage.getItem("activeaddress")) {
            let index = Number(window.localStorage.getItem("activeaddress"))

            if (Address.length - 1 < index) {
                window.localStorage.removeItem("activeaddress")
            }
        }

        for (let i = 0; i < Address.length; i++) {
            if (Address[i].AddressType === "Default" && window.localStorage.getItem("activeaddress") === null) {
                window.localStorage.setItem("activeaddress", i)
                handleSelectAddress(i)


            }
        }
        if (window.localStorage.getItem("activeaddress") !== null) {
            handleSelectAddress(Number(window.localStorage.getItem("activeaddress")))
        } else {
            handleSelectAddress(0)
        }

       



    }, [props])
   

    const navigate = useNavigate()
    const removeItem = (productId) => {
        props.setcartdata(prevData => {
            const updatedData = prevData.filter(item => item.productid !== productId);
            window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
            return updatedData;
        });

        if (props.cartdata.length === 1) {
            localStorage.removeItem("activeaddress")
            navigate('/bldc-ceiling-fan');
        }
    };

    const handleChangeQuantity = (productId, newQuantity) => {
        if (newQuantity === "" || newQuantity === 0) {
            toast.error("Please enter a valid Quantity"); // Display an error toast notification
            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {
                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        } else {
            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {
                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        }

        if(window.localStorage.getItem("coupancode") !== null){
            applyCoupan(window.localStorage.getItem("coupancode"))
        }
    };
    const [showMore, setShowMore] = useState(false);
    const [showarr, setShowArr] = useState([0]);


    const toggleShowMore = () => {
        setShowMore(!showMore);
        if (showMore) {
            setShowArr([0]);
        } else {
            setShowArr(props.cartdata.map((_, index) => index));
        }
    };
    const [selectedAddress, setSelectedAddress] = useState(null);


    const handleSelectAddress = (index) => {

        setSelectedAddress(index);
        setactiveaddress(index)
        window.localStorage.setItem("activeaddress", index)
    };

    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Address - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Address - Colorbot"; // Reset the title when component unmounts
        };
    }, []);
    const addaddress = async () => {
        if (Country.length < 1) {
            toast.error("Please enter a valid Country"); // Display an error toast notification
        } else if (addfirst.length < 1) {
            toast.error("Please enter a valid First Name"); // Display an error toast notification
        } else if (addlast.length < 1) {
            toast.error("Please enter a valid Last Name"); // Display an error toast notification
        } else if (Address.length < 1) {
            toast.error("Please enter a valid Address"); // Display an error toast notification
        } else if (pincode.length !== 6) {
            toast.error("Please enter a valid Pincode"); // Display an error toast notification
        } else if (City.length < 1) {
            toast.error("Please enter a valid City"); // Display an error toast notification
        } else if (State.length < 1) {
            toast.error("Please enter a valid State"); // Display an error toast notification
        } else if (/^[6-9]\d{9}$/.test(addphone) === false) {
            toast.error("Please enter a valid 10-digit phone number"); // Display an error toast notification
        } else {
            var data = {
                "FirstName": addfirst,
                "LastName": addlast,
                "Address": Address,
                "Address1": Address1,
                "Pincode": pincode,
                "City": City,
                "State": State,
                "Country": Country,
                "Phone": "+91" + addphone,
                "AddressType": addtype === true ? "Default" : "Other",

            }
            var addressarr = []
            var address = window.localStorage.getItem("Address") === "" ? null : JSON.parse(window.localStorage.getItem("Address"))
            if (address === null) {

            } else {
                addressarr = address
                if (editindex === null) {
                    if (addtype === true) {
                        for (let i = 0; i < addressarr.length; i++) {

                            addressarr[i].AddressType = "Other"

                        }
                    }

                    addressarr.push(data)
                } else {
                    addressarr[editindex] = data
                }
            }
            if (addtype === true && editindex !== null) {
                for (let i = 0; i < addressarr.length; i++) {
                    if (i === editindex && addtype === true) {
                        addressarr[i].AddressType = "Default"
                    } else {
                        addressarr[i].AddressType = "Other"
                    }
                }
            }

            try {
                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserAddressUpdate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                    },

                    body: JSON.stringify({ Address: addressarr, Phone: window.localStorage.getItem("phoneNumber") }),
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {

                        window.localStorage.setItem('token', response.data.token);
                        window.localStorage.setItem('UserId', response.data.UserId);
                        if (response.data.Email !== undefined) { window.localStorage.setItem("Email", response.data.Email) }
                        if (response.data.FirstName !== undefined) { window.localStorage.setItem("FirstName", response.data.FirstName) }
                        if (response.data.LastName !== undefined) { window.localStorage.setItem("LastName", response.data.LastName) }
                        if (response.data.Address !== undefined) { window.localStorage.setItem("Address", JSON.stringify(response.data.Address)) }


                        setAddress("")
                        setAddress1("")
                        setaddlast("")
                        setaddfirst("")
                        setaddphone("")
                        setaddtype(false)
                        setPincode("")
                        setCity("")

                        setState("")
                        setLgShow(false)
                        setCountry("India")
                        var Address = window.localStorage.getItem("Address") === "" ? null : JSON.parse(window.localStorage.getItem("Address"))

                        for (let i = 0; i < Address.length; i++) {
                            if (editindex !== null && Address[editindex].AddressType === "Default" && window.localStorage.getItem("activeaddress") === null) {
                                window.localStorage.setItem("activeaddress", editindex)
                                handleSelectAddress(editindex)


                            }
                        }
                        for (let i = 0; i < Address.length; i++) {
                            if (Address[i].AddressType === "Default" && editindex === null) {
                                window.localStorage.setItem("activeaddress", i)
                                handleSelectAddress(i)
                            }
                        }
                        if (window.localStorage.getItem("activeaddress") !== null && editindex !== null) {
                            window.localStorage.setItem("activeaddress", editindex)
                            handleSelectAddress(editindex)
                        }

                        setaddressdata(JSON.parse(window.localStorage.getItem("Address")))
                        seteditindex(null)




                        toast.success(response.message);
                    } else {

                        toast.error(response.message);
                    }
                })
            } catch (error) {
                console.error('API Error:', error);
                toast.error(error);
            }

        }
    }

    const checkpincode = (e) => {
        setCity('');
        setState('');
        var value = e.target.value;
        setPincode(value);

        if (value.length === 6) {
            setError('');

            axios
                .get(`https://api.postalpincode.in/pincode/${value}`)
                .then((res) => {
                    setState(res.data[0].PostOffice[0].State);
                    setCity(res.data[0].PostOffice[0].Block);


                })
                .then(() => {

                })
                .catch((err) => {

                    setError('Invalid PIN Code');
                });
        }

        if (value.length !== 6) {
            setCity('');

            setState('');
            setError('Pincode must be of 6 digits');
        }

    }
    const AddAddress = () => {
        setaddfirst("")
        setaddlast("")
        setAddress("")
        setAddress1("")
        setPincode("")
        setCity("")
        setState("")
        setCountry("India")
        setaddphone("")
        setaddtype(false)
        setLgShow(true)
        seteditindex(null)
    }
    const updateAddress = async (index) => {
        var address = window.localStorage.getItem("Address") === "" ? null : JSON.parse(window.localStorage.getItem("Address"))
        setaddfirst(address[index].FirstName)
        setaddlast(address[index].LastName)
        setAddress(address[index].Address)
        setAddress1(address[index].Address1)
        setPincode(address[index].Pincode)
        setCity(address[index].City)
        setState(address[index].State)
        setCountry(address[index].Country)
        setaddphone(address[index].Phone.slice(3, 13))
        seteditindex(index)

        if (address[index].AddressType === "Default") {
            setaddtype(true)
        } else {
            setaddtype(false)
        }


        setLgShow(true)
    }


    const [coupanlist, setcoupanlist] = useState([])
    const [coupancode, setcoupancode] = useState("")
    const [coupanprice, setcoupanprice] = useState(0)
    const [userlist, setUserList] = useState([])
    const getUser = async () => {
        try {

            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetUserCoupan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
                body: JSON.stringify({ UserId: window.localStorage.getItem("UserId") })

            });

            if (response.status === 200) {
                const data = await response.json();
                setUserList(data.data)
                getCoupan()

            }
        } catch (error) {
            console.error('API Error:', error);

        }
    }

    const getCoupan = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetWebCoupan`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                let filterdata = []
                for (let i = 0; i < data.data.length; i++) {


                    filterdata.push(data.data[i])




                }

                for (let i = 0; i < userlist.length; i++) {
                    for (let j = 0; j < filterdata.length; j++) {
                        if (userlist[i].coupanid === filterdata[j].coupon_code && filterdata[j].gift === "first") {
                            if(window.localStorage.getItem("coupancode") === filterdata[j].coupon_code){
                                setcoupanprice(0)
                                window.localStorage.removeItem("coupanprice")
                                window.localStorage.removeItem("coupancode")
                            }
                            filterdata.splice(j, 1)
                        }
                        if (userlist[i].coupanid === filterdata[j].coupon_code && filterdata[j].gift === "gift") {
                            if(window.localStorage.getItem("coupancode") === filterdata[j].coupon_code){
                                setcoupanprice(0)
                                window.localStorage.removeItem("coupanprice")
                                window.localStorage.removeItem("coupancode")
                            }
                            filterdata.splice(j, 1)
                        }
                    }
                }

                var filterdata1 = []
                for (let i = 0; i < filterdata.length; i++) {
                    if (Number(filterdata[i].noOfUsers) > 0 || filterdata[i].noOfUsers === undefined || filterdata[i].noOfUsers === "") {
                        filterdata1.push(filterdata[i])
                    }

                }


                setcoupanlist(filterdata1)

            }
        } catch (error) {
            console.error('API Error:', error);

        }
    }
    const applyCoupan = async (code) => {
        if (code === "") {
            const data = coupanlist.filter((data) => {
                return data.coupon_code === coupancode.toUpperCase()
            })

            if (data.length > 0) {
                if (parseInt(Subtotal) > parseInt(data[0].discount)) {
                    if (data[0].discount_type === "Rs.") {
                        if (Subtotal >= data[0].min_cart_value) {

                            setcoupanprice(data[0].discount)
                            window.localStorage.setItem("coupanprice", parseInt(data[0].discount))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                            setCoupanActive(true)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    } else {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice((Subtotal * data[0].discount) / 100)
                            window.localStorage.setItem("coupanprice", ((Subtotal * parseInt(data[0].discount)) / 100))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    }
                } else {
                    toast.error("Amount should be greater than " + data[0].discount)
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setLgShow1(false);
                    setCoupanActive(false)
                }
            } else {
                toast.error("Please enter a valid coupon code")
            }

        } else {
            const data = coupanlist.filter((data) => {
                return data.coupon_code === code
            })

            if (data.length > 0) {
               
                if (parseInt(Subtotal) > parseInt(data[0].discount)) {
                    if (data[0].discount_type === "Rs.") {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice(parseInt(data[0].discount))
                            window.localStorage.setItem("coupanprice", parseInt(data[0].discount))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    } else {
                        if (Subtotal >= data[0].min_cart_value) {
                            setcoupanprice((Subtotal * parseInt(data[0].discount)) / 100)
                            window.localStorage.setItem("coupanprice", ((Subtotal * parseInt(data[0].discount)) / 100))
                            window.localStorage.setItem("coupancode", data[0].coupon_code)
                            setLgShow1(false)
                        }
                        else {
                            toast.error("Minimum cart value should be " + data[0].min_cart_value)
                            setcoupancode("")
                        }
                    }
                } else {
                    toast.error("Amount should be greater than " + data[0].discount)
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setLgShow1(false);
                    setCoupanActive(false)
                }
            } else {
                toast.error("Please enter a valid coupon code")
            }

        }
    }
    const [coupanActive, setCoupanActive] = useState(false)
    useEffect(() => {
        if (window.localStorage.getItem("coupanprice")) {
            setcoupanprice(parseInt(window.localStorage.getItem("coupanprice")))
        }
        if (window.localStorage.getItem("coupancode")) {
            setcoupancode(window.localStorage.getItem("coupancode"))
            setCoupanActive(true)
        } else {

            setCoupanActive(false)
        }

        getUser()

    }, [lgShow1])
    const navigateToProduct = (path) => {
        navigate(path);
    }
    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem("cartdata")).length === 0) {
            navigate("/bldc-ceiling-fan")
        }
    }, [])

    return (
        <section className="mt-5 pt-5 pb-5 buynowbackground">
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ marginTop: "" }}
                centered
                show={lgShow}
                onHide={() => setLgShow(false)}

            >
                <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                </Modal.Header>
                <Modal.Body style={{ paddingTop: "0rem" }}>


                    <div className="profile-pop-boxxx">


                        <div className="profile-pop-box2 deliv-add">
                            <div className="profile-pop-text text-center">
                                <span>{editindex === null ? "Add" : "Edit"} Address</span>

                            </div>
                            <div className="form-profile-pop">

                                <select className="form-select  form-alignment" value={Country} onChange={(e) => {
                                    setCountry(e.target.value)
                                }}>

                                    <option value="India">India</option>

                                </select>
                                <div className="row">
                                    <div className="col-md-6 form-partition">
                                        <input type="text" className="form-control form-alignment" placeholder="First Name" value={addfirst} onChange={(e) => {
                                            setaddfirst(e.target.value)
                                        }} />
                                    </div>
                                    <div className="col-md-6 form-partition">
                                        <input type="text" className="form-control form-alignment" placeholder="Last Name" value={addlast} onChange={(e) => {
                                            setaddlast(e.target.value)
                                        }} />
                                    </div>
                                </div>



                                <input type="text" className="form-control form-alignment" placeholder="Address 1" value={Address} onChange={(e) => {
                                    setAddress(e.target.value)
                                }} />

                                <input type="text" className="form-control form-alignment" placeholder="Address 2" value={Address1} onChange={(e) => {
                                    setAddress1(e.target.value)
                                }} />

                                <div className="row">
                                    <div className="col-md-4 form-partition">
                                        <input type="text" className="form-control form-alignment " placeholder="PIN Code" value={pincode} onChange={(e) => {
                                            checkpincode(e)
                                        }} />
                                    </div>
                                    <div className="col-md-4 form-partition">
                                        <input type="text" className="form-control form-alignment " placeholder="City" readOnly value={City} onChange={(e) => {
                                            setCity(e.target.value)
                                        }} />
                                    </div>
                                    <div className="col-md-4 form-partition">
                                        <input type="text" className="form-control form-alignment " placeholder="State" readOnly value={State} onChange={(e) => {
                                            setState(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <span className="text-danger " style={{ marginTop: "1px" }}>{error}</span>

                                <input type="text" className="form-control form-alignment " maxLength={10} placeholder="Phone" value={addphone} onChange={(e) => {
                                    setaddphone(e.target.value)
                                }} />


                            </div>

                            <div className="check-box d-flex">
                                <input type="checkbox" checked={addtype} onChange={() => {
                                    setaddtype(!addtype)
                                }} /><span>Set as default address</span>
                            </div>

                            <div className="d-flex justify-content-center">
                                <input className="btn button-profile-pop button-signin" type="button" value={"Continue"} onClick={() => {
                                    addaddress()
                                }} />
                            </div>




                        </div>
                    </div>




                </Modal.Body>
            </Modal>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-8">
                        <div className="logincard">
                            {window.innerWidth < 780 ? <div className="productcard">
                                {props.cartdata.map((data, index) => {
                                    const isVisible = showarr.includes(index);
                                    return (
                                        <div className={isVisible ? "productbuynowcard" : "productbuynowcard hidden"} key={index}>
                                            <img src={data.productimage} className="img-fluid" alt={index} style={{
                                                            cursor: "pointer"
                                                        }} onClick={() => {
                                                            navigateToProduct(data.productpath)
                                                        }}/>
                                            <div className="productbuynowcardbody">
                                                <div className="cartproducttitle">{data.productname}</div>
                                                {/* <div className="cartproductcolor"><span style={{ color: "#8A8A8A" }}>Color:</span> {data.productcolor}</div> */}
                                                <div className="fan-specs">
                                                    <div className="cartproductcolor">{data.productcolor}</div>
                                                    <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                                </div>
                                                <div className="cartproductquantity">
                                                    <div className="cartproductquantitytitle"></div>
                                                    &nbsp;
                                                    <ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid} />
                                                    {/* <input id={`quantity${index}`}
                                                        type="number"
                                                        className="helixselect cartproductquantityselect" style={{ padding: "2px 2px", marginTop: "0px" }}
                                                        value={data.productquantity}
                                                        onChange={(e) => {
                                                            const inputValue = parseInt(e.target.value, 10);

                                                            if (inputValue < 0 || inputValue > 99) {
                                                                // If the input is invalid, you can choose to handle it here.
                                                                // For example, you might want to show an error message or prevent further processing.
                                                                return;
                                                            }
                                                            handleChangeQuantity(data.productid, e.target.value)

                                                        }}
                                                    /> */}

                                                    <div className="cartproductremove" onClick={() => {
                                                        removeItem(data.productid)
                                                    }}>Remove</div>

                                                    <div className="cartproductprice">Rs.{data.productdiscountedprice}</div>


                                                </div>
                                                {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}

                                            </div>

                                        </div>
                                    );
                                })}
                                {props.cartdata.length > 1 ? <div className="productshowmore" onClick={toggleShowMore}>
                                    {showMore ? "Show Less" : "Show More"} &nbsp; &nbsp;
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                                </div> : ""}
                            </div> : ""}
                            <div className="logincardhead cardheadactive deliverystart">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><span style={{ height: "27px" }}>1</span> &nbsp;&nbsp;&nbsp;
                                    <div style={{ marginTop: "5px" }}><h2>Login/Signup</h2>
                                        <h6 className="deliverynumber">{window.localStorage.getItem("phoneNumber") !== null ? <> {window.localStorage.getItem("phoneNumber").slice(3, 13)}</> : ""}</h6></div>
                                </div>
                                <NavLink to="/product_buy_now_login" className="buynowdecoration">  <button className="btn btnchange">Change</button></NavLink>
                            </div>

                            <br />
                            <NavLink to="/product_buy_now_delivery" className="buynowdecoration"><div className="logincardhead ">
                                <span>2</span> &nbsp;&nbsp;&nbsp;
                                <h2>Billing/Delivery Address</h2>
                            </div></NavLink>
                            <div className="logincardbody">
                                {addressdata.length > 0 ? <> {addressdata.map((data, index) => {
                                    const isSelected = selectedAddress === index || activeaddress === index;
                                    return (
                                        <div className="addressbox" key={index}>
                                            <div style={{ display: "flex" }}> <input className="form-check-input buy-address-check" type="radio" name={"flexRadioDefault"} id={"flexRadioDefaultaddress" + index} checked={isSelected} onClick={() => {
                                                handleSelectAddress(index)
                                            }} />
                                                &nbsp; &nbsp; <div htmlFor={"flexRadioDefaultaddress" + index}>  <h3>{data.FirstName} {data.LastName} &nbsp;&nbsp; <span>{data.AddressType}</span></h3>
                                                    <h5>{data.Address} {data.Address1}, {data.City}, {data.State}, {data.Country}, ({data.Pincode}) <b>{data.Phone.slice(3, 13)}</b>
                                                        <b style={{ display: "block" }}>{data.Email}</b></h5></div></div>
                                            <div className="addresscolumn">
                                                <span style={{ cursor: "pointer" }} onClick={() => {
                                                    updateAddress(index)

                                                }}><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/editaddress.svg" alt="edit" />  &nbsp;Edit</span>
                                                {/* <button className="btn btnselectaddress" id="" disabled={!isSelected} onClick={() => {
                                                    navigate("/product_buy_now_order_summary")
                                                }}
                                                >Select</button> */}
                                            </div>
                                        </div>
                                    );
                                })}
                                    <div className="addresscolumn addres-slct-btn" style={{ alignItems: "center", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between", paddingTop: "10px" }}>
                                        <span style={{ cursor: "pointer" }} onClick={() => {
                                            AddAddress()

                                        }}><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/plus.svg" alt="edit" />  &nbsp;Add new address</span>
                                        {addressdata.length > 0 && (Subtotal-coupanprice)>0? <button className="btn btnselectaddress" id="" onClick={() => {
                                            navigate("/product_buy_now_order_summary")
                                        }}
                                        >Select & Proceed</button> : ""}
                                        
                                    </div>




                                </> :

                                    <div className="profile-pop-box2 deliv-add">
                                        {/* <div className="profile-pop-text text-center">
                                            <span>{addresstype} Address</span>

                                        </div> */}
                                        <div className="form-profile-pop">

                                            <select className="form-select  form-alignment" value={Country} onChange={(e) => {
                                                setCountry(e.target.value)
                                            }}>

                                                <option value="India">India</option>

                                            </select>
                                            <div className="row"> 
                                                <div className="col-md-6 form-partition">
                                                    <input type="text" className="form-control form-alignment" placeholder="First Name" value={addfirst} onChange={(e) => {
                                                        setaddfirst(e.target.value)
                                                    }} />
                                                </div>
                                                <div className="col-md-6 form-partition">
                                                    <input type="text" className="form-control form-alignment" placeholder="Last Name" value={addlast} onChange={(e) => {
                                                        setaddlast(e.target.value)
                                                    }} />
                                                </div>
                                            </div>



                                            <input type="text" className="form-control form-alignment" placeholder="Address 1" value={Address} onChange={(e) => {
                                                setAddress(e.target.value)
                                            }} />

                                            <input type="text" className="form-control form-alignment" placeholder="Address 2" value={Address1} onChange={(e) => {
                                                setAddress1(e.target.value)
                                            }} />

                                            <div className="row">
                                                <div className="col-md-4 form-partition">
                                                    <input type="text" className="form-control form-alignment " placeholder="PIN Code" value={pincode} onChange={(e) => {
                                                        checkpincode(e)
                                                    }} />
                                                </div>
                                                <div className="col-md-4 form-partition">
                                                    <input type="text" className="form-control form-alignment " placeholder="City" readOnly value={City} onChange={(e) => {
                                                        setCity(e.target.value)
                                                    }} />
                                                </div>
                                                <div className="col-md-4 form-partition">
                                                    <input type="text" className="form-control form-alignment " placeholder="State" readOnly value={State} onChange={(e) => {
                                                        setState(e.target.value)
                                                    }} />
                                                </div>
                                            </div>
                                            <span className="text-danger " style={{ marginTop: "1px" }}>{error}</span>

                                            <input type="text" className="form-control form-alignment " min={"1"} max={"10"} placeholder="Phone" value={addphone} onChange={(e) => {
                                                setaddphone(e.target.value)
                                            }} />


                                        </div>

                                        <div className="check-box d-flex">
                                            <input type="checkbox" checked={addtype} onChange={() => {
                                                setaddtype(!addtype)
                                            }} /><span>Set as default address</span>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <input className="btn button-profile-pop button-signin" type="button" value={"Continue"} onClick={() => {
                                                addaddress()
                                            }} />
                                        </div>



                                    </div>}
                            </div>
                            <br />

                            <div className="logincardhead cardheadactive">
                                <span>3</span> &nbsp;&nbsp;&nbsp;
                                <h2>Detailed Order Summary</h2>
                            </div>

                            {window.innerWidth < 780 ? <div className="coupanproductcard">
                                <div className="coupaninput">
                                    <input type="text" className="form-control" id="exampleInputcoupan1" onClick={() => {
                                        setLgShow1(true)
                                    }} placeholder="Coupon" style={{
                                        textTransform: "uppercase"
                                    }} value={coupancode} readOnly />
                                    <button className="btn btncoupan" onClick={() => {
                                        setLgShow1(true)
                                    }}>VIEW</button>
                                </div>

                                <div className="coupanproductcardbody" style={{ borderTop: "2px solid #DDDDDD", marginTop: "1rem" }}>
                                    <span className="productcardbodytitle">Subtotal</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal / 1.18).toFixed(2)}</span>
                                </div>
                                {shippingPrice > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Shipping</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {shippingPrice}</span></span>
                                </div> : ""}
                                {extendedwarranty > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Extended Warranty</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {totalextendedwarranty}</span></span>
                                </div> : ""}

                                {coupanprice > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Coupon Discount</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">-Rs. {coupanprice}</span></span>
                                </div> : ""}
                                {addressdata?.[activeaddress]?.State === "Uttar Pradesh" ? <div className="coupanproductcardbody">

                                    <span className="productcardbodytitle">Tax (CGST 9%, SGST 9%)</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                                </div> : <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Tax (IGST 18%)</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                                </div>}

                                <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle productamount" style={{ color: "#242933", }}>Amount Payable <small> (incl. tax)</small> </span>
                                    <span className="productcardbodyshipping"><span className="productgrandtotal productamountprice ">Rs. {Subtotal - coupanprice}</span></span>
                                </div>

                            </div> : ""}

                            <div className="logincardhead cardheadactive mt-3">
                                <span>4</span> &nbsp;&nbsp;&nbsp;
                                <h2>Payment Options</h2>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-12  col-lg-4">
                        {window.innerWidth > 780 ? <div className="productcard">
                            {props.cartdata.map((data, index) => {
                                const isVisible = showarr.includes(index);
                                return (
                                    <div className={isVisible ? "productbuynowcard" : "productbuynowcard hidden"} key={index}>
                                        <img src={data.productimage} className="img-fluid" alt={index} style={{
                                                            cursor: "pointer"
                                                        }} onClick={() => {
                                                            navigateToProduct(data.productpath)
                                                        }}/>
                                        <div className="productbuynowcardbody">
                                            <div className="cartproducttitle">{data.productname}</div>
                                            {/* <div className="cartproductcolor"><span style={{ color: "#8A8A8A" }}>Color:</span> {data.productcolor}</div> */}
                                            <div className="fan-specs">
                                                <div className="cartproductcolor">{data.productcolor}</div>
                                                <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                            </div>
                                            <div className="cartproductquantity">
                                                <div className="cartproductquantitytitle"></div>
                                                &nbsp;
                                                <ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid} />
                                                {/* <input id={`quantity${index}`}
                                                    type="number"
                                                    className="helixselect cartproductquantityselect" style={{ padding: "2px 2px", marginTop: "0px" }}
                                                    value={data.productquantity}
                                                    onChange={(e) => {
                                                        const inputValue = parseInt(e.target.value, 10);

                                                        if (inputValue < 0 || inputValue > 99) {
                                                            // If the input is invalid, you can choose to handle it here.
                                                            // For example, you might want to show an error message or prevent further processing.
                                                            return;
                                                        }
                                                        handleChangeQuantity(data.productid, e.target.value)

                                                    }}
                                                /> */}

                                                <div className="cartproductremove" onClick={() => {
                                                    removeItem(data.productid)
                                                }}>Remove</div>

                                                <div className="cartproductprice">Rs.{data.productdiscountedprice}</div>


                                            </div>
                                            {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}

                                        </div>

                                    </div>
                                );
                            })}
                            {props.cartdata.length > 1 ? <div className="productshowmore" onClick={toggleShowMore}>
                                {showMore ? "Show Less" : "Show More"} &nbsp; &nbsp;
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                            </div> : ""}
                        </div> : ""}

                        {window.innerWidth > 780 ? <div className="coupanproductcard">
                            <div className="coupaninput">
                                <input type="text" className="form-control" id="exampleInputcoupan1" placeholder="Coupon" onClick={() => {
                                    setLgShow1(true)
                                }} style={{
                                    textTransform: "uppercase"
                                }} value={coupancode} readOnly />
                                <button className="btn btncoupan" onClick={() => {
                                    setLgShow1(true)
                                }}>VIEW</button>
                            </div>

                            <div className="coupanproductcardbody" style={{ borderTop: "2px solid #DDDDDD", marginTop: "1rem" }}>
                                <span className="productcardbodytitle">Subtotal</span>
                                <span className="productcardbodydata">Rs. {(Subtotal / 1.18).toFixed(2)}</span>
                            </div>
                            {shippingPrice > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Shipping</span>
                                <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {shippingPrice}</span></span>
                            </div> : ""}
                            {extendedwarranty > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Extended Warranty</span>
                                <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {totalextendedwarranty}</span></span>
                            </div> : ""}

                            {coupanprice > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Coupon Discount</span>
                                <span className="productcardbodyshipping"><span className="productshipping">-Rs. {coupanprice}</span></span>
                            </div> : ""}
                            {addressdata?.[activeaddress]?.State === "Uttar Pradesh" ? <div className="coupanproductcardbody">

                                <span className="productcardbodytitle">Tax (CGST 9%, SGST 9%)</span>
                                <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                            </div> : <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Tax (IGST 18%)</span>
                                <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                            </div>}

                            <div className="coupanproductcardbody">
                                <span className="productcardbodytitle productamount" style={{ color: "#242933", }}>Amount Payable <small> (incl. tax)</small> </span>
                                <span className="productcardbodyshipping"><span className="productgrandtotal productamountprice ">Rs. {Subtotal - coupanprice}</span></span>
                            </div>

                        </div> : ""}

                        <div className="productsafety mt-3">
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/secure.svg" className="img-fluid" alt="secure" />
                                <div className="productsafetydatatext">
                                    Secure <p>Payment</p>
                                </div>
                            </div>
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/guaranted.svg" className="img-fluid" alt="guaranted" />
                                <div className="productsafetydatatext">
                                    Satisfaction<p> Guaranteed</p>
                                </div>
                            </div>
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/privacybuy.svg" className="img-fluid" alt="privacy" />
                                <div className="productsafetydatatext">
                                    Your privacy <p>is priority</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"

                centered
                show={lgShow1}

                onHide={() => setLgShow1(false)}

            >
                <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>
                    <span className="coupantitle">COUPONS</span>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: "0rem" }}>


                    <div className="box-profile-pop-profile">





                        <div className="coupaninput p-3 pt-4">
                            <input type="text" className="form-control" id="exampleInputcoupan1" placeholder="Coupon" style={{
                                textTransform: "uppercase"
                            }} value={coupancode} onChange={(e) => {
                                setcoupancode(e.target.value)

                            }} />
                            {coupanActive ? <button className="btn btncoupan" style={{
                                color: "white",
                                backgroundColor: "#CB0000"
                            }} onClick={() => {
                                setcoupancode("");
                                setcoupanprice(0);
                                window.localStorage.removeItem("coupanprice");
                                window.localStorage.removeItem("coupancode");
                                setLgShow1(false);
                                setCoupanActive(false)
                            }}>Remove</button> :
                                <button className="btn btncoupan" onClick={() => {
                                    applyCoupan("")
                                }}>APPLY</button>}

                        </div>

                        <div className="coupancodebody">
                            {coupanlist.length > 0 && coupanlist.filter((data) => {
                                return data.gift !== "gift"
                            }).map((data, index) => {
                                return (
                                    <div className="coupanbody" key={index}>


                                        <div>

                                            <p className="coupancode">{data.coupon_code}</p>
                                            <p className="coupandescription">{data.coupon_description}</p>
                                        </div>
                                        <div className="coupanapply">
                                            <button className="btn btncoupanapply" onClick={() => {
                                                setcoupancode(data.coupon_code)
                                                applyCoupan(data.coupon_code)
                                            }}>APPLY</button>
                                        </div>


                                    </div>
                                )
                            })
                            }

                        </div>









                    </div>


                </Modal.Body>
            </Modal>
        </section>
    );
};
export default Buynowdelivery;
