
import "./Product.css";
import { useEffect, useRef, useState } from 'react';
import FadeInSection from "../FadeInup/FadeInup";
import { NavLink } from "react-router-dom";
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet';


const ProductHead = () => {

    const [metaData, setMetaData] = useState({
        urlSlug: '',
        titleTag: '',
        metaDescription: '',
        markupName: '',
        jsonLdMarkup: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: '',
    });

    const [jsonData,setJsonData] = useState(null);

    const fetchSeoData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/seo-settings/seo001`);
            if (response.ok) {
                const result = await response.json();
                const data = result.data;
                setMetaData({
                    urlSlug: data.urlSlug || '',
                    titleTag: data.titleTag || '',
                    metaDescription: data.metaDescription || '',
                    markupName: data.markupName || '',
                    jsonLdMarkup: data.jsonLdMarkup || '',
                    ogTitle: data.ogTitle || '',
                    ogDescription: data.ogDescription || '',
                    ogImage: data.ogImage || '',
                });

                const jsonObjects = JSON.parse(`[${data.jsonLdMarkup}]`);
               
                setJsonData(jsonObjects);
            }
        } catch (error) {

        }

    };



    useEffect(() => {
        fetchSeoData();
    }, [])
    return (
        <>
            <Helmet>
                {/* Dynamically setting title, meta, and Open Graph tags */}
                <title>{metaData.titleTag}</title>
                <meta name="description" content={metaData.metaDescription} />
                <link rel="canonical" href={metaData.urlSlug} />
                <meta property="og:title" content={metaData.ogTitle} />
                <meta property="og:site_name" content="Colorbot" />
                <meta property="og:url" content={metaData.urlSlug} />
                <meta property="og:description" content={metaData.ogDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={metaData.ogImage} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content=" Buy BLDC Ceiling Fans | No.1 Premium BLDC Fans - Colorbot" />
                <meta name="twitter:description" content=" Shop an extensive range of BLDC ceiling fans online with remote control. Upgrade your home with premium power saving bldc fans." />
                <meta name="twitter:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-coupon.webp" />
              
              
                {/* Dynamically include JSON-LD markup */}
                {jsonData && jsonData.map((data, index) => (
                    
                    <script type="application/ld+json" key={index}>
                        {JSON.stringify(data)}
                    </script>   
                ))}
                
            </Helmet>

            {/* // <Helmet>

    //     <title>Buy BLDC Ceiling Fans | No.1 Premium BLDC Fans - Colorbot</title>
    //     <meta name="description" content="Shop an extensive range of BLDC ceiling fans online with remote control. Upgrade your home with premium power saving bldc fans." />
    //     <link rel="canonical" href="https://colorbot.in/bldc-ceiling-fan" />

    //     <meta property="og:title" content=" Buy BLDC Ceiling Fans | No.1 Premium BLDC Fans - Colorbot" />
    //     <meta property="og:site_name" content="Colorbot" />
    //     <meta property="og:url" content="https://colorbot.in/bldc-ceiling-fan" />
    //     <meta property="og:description" content=" Shop an extensive range of BLDC ceiling fans online with remote control. Upgrade your home with premium power saving bldc fans." />
    //     <meta property="og:type" content="website" />
    //     <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-coupon.webp" />
    //     <meta name="twitter:card" content="summary_large_image" />
    //     <meta name="twitter:title" content=" Buy BLDC Ceiling Fans | No.1 Premium BLDC Fans - Colorbot" />
    //     <meta name="twitter:description" content=" Shop an extensive range of BLDC ceiling fans online with remote control. Upgrade your home with premium power saving bldc fans." />
    //     <meta name="twitter:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-coupon.webp" />

    // </Helmet> */}
        </>
    )
}
    ;

function Products() {
    const [width, setwidth] = useState(window.innerWidth);
    const [helixList, sethelixList] = useState([])
    const [knoxxList, setknoxxList] = useState([])
    const [stellaList, setstellaList] = useState([])
    const [producttype, setproducttype] = useState([])
    const [colortype, setcolortype] = useState([])
    const [knoxxQuadList, setknoxxQuadList] = useState([])
    const [stellaQuadList, setstellaQuadList] = useState([])
    const [prizmaList, setprizmaList] = useState([])
    const [activecolor, setactivecolor] = useState("#000000")
    const [activetype, setactivetype] = useState("Helix")
    const [knoxxactivecolor, setknoxxactivecolor] = useState("#000000")
    const [knoxxactivetype, setknoxxactivetype] = useState("Knoxx")
    const [stellaactivecolor, setstellaactivecolor] = useState("#000000")
    const [stellaactivetype, setstellaactivetype] = useState("Stella")
    const [knoxxQuadactivecolor, setknoxxQuadactivecolor] = useState("#000000")
    const [knoxxQuadactivetype, setknoxxQuadactivetype] = useState("Knoxx-Quad")
    const [stellaQuadactivecolor, setstellaQuadactivecolor] = useState("#000000")
    const [stellaQuadactivetype, setstellaQuadactivetype] = useState("Stella-Quad")
    const [prizmaactivecolor, setprizmaactivecolor] = useState("#000000")
    const [prizmaactivetype, setprizmaactivetype] = useState("Prizma")

    const [colorlist, setcolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])
    const [knoxxcolorlist, setknoxxcolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])
    const [stellacolorlist, setstellacolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])
    const [prizmacolorlist, setprizmacolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])
    const [knoxxquadcolorlist, setknoxxquadcolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])
    const [stellaquadcolorlist, setstellaquadcolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])




    const [activeproduct, setactiveproduct] = useState({})
    const [knoxxactiveproduct, setknoxxactiveproduct] = useState({})
    const [stellaactiveproduct, setstellaactiveproduct] = useState({})
    const [knoxxQuadactiveproduct, setknoxxQuadactiveproduct] = useState({})
    const [stellaQuadactiveproduct, setstellaQuadactiveproduct] = useState({})
    const [priceArray, setpriceArray] = useState([])
    const [checkLed, setcheckLed] = useState([])
    const [prizmaactiveproduct, setprizmaactiveproduct] = useState({})
    useEffect(() => {

        window.scrollTo(0, 0);
        setwidth(window.innerWidth);
    }, []);

    useEffect(() => {
        document.title = "Buy BLDC Ceiling Fan Online in India at Best Price - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Buy BLDC Ceiling Fan Online in India at Best Price - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    // LOADER CODE
    const [showLoader, setShowLoader] = useState(true)

    const [sidePanelOpen, setSidePanelOpen] = useState(false);

    const intervalRef = useRef(null);
    const nextSlideButtonRef = useRef(null);



    useEffect(() => {
        if (!showLoader) {
            intervalRef.current = setInterval(() => {

                if (!document.getElementById("collapsibleNavbar").classList.contains("show") && !document.getElementById("sidePanel").classList.contains("open")) {
                    if (nextSlideButtonRef.current) {
                        nextSlideButtonRef.current.click();
                    }
                }
            }, 5000);
        }

        return () => clearInterval(intervalRef.current);
    }, [showLoader]);

    // function countAvailableColors(arr, arr1) {
    //     var count = 0;
    //     arr.forEach(function (obj) {
    //         if (arr1.includes(obj.color) && obj.available) {
    //             count++;
    //         }
    //     });
    //     console.log(count)
    //     return count;
    // }
    const handleButtonClick = () => {

        setSidePanelOpen(true);
    };

    const handleCloseClick = () => {
        setSidePanelOpen(false);
    };
    const [currentwidth, setcurrentwidth] = useState(window.innerWidth);
    useEffect(() => {
        setcurrentwidth(window.innerWidth)
        localStorage.setItem("activeColor", "")
        localStorage.setItem("activeType", "")
        getproduct()
    }, [])

    const updateproducttype = (type) => {
        if (type === "Helix") {
            producttype?.includes("Helix") ? setproducttype(producttype?.filter(item => item !== "Helix")) : setproducttype([...producttype, "Helix"])
        } else if (type === "Knoxx") {
            producttype?.includes("Knoxx") ? setproducttype(producttype?.filter(item => item !== "Knoxx")) : setproducttype([...producttype, "Knoxx"])
        } else if (type === "Stella") {
            producttype?.includes("Stella") ? setproducttype(producttype?.filter(item => item !== "Stella")) : setproducttype([...producttype, "Stella"])
        } else if (type === "Knoxx-Quad") {
            producttype?.includes("Knoxx-Quad") ? setproducttype(producttype?.filter(item => item !== "Knoxx-Quad")) : setproducttype([...producttype, "Knoxx-Quad"])
        } else if (type === "Stella-Quad") {
            producttype?.includes("Stella-Quad") ? setproducttype(producttype?.filter(item => item !== "Stella-Quad")) : setproducttype([...producttype, "Stella-Quad"])
        } else if (type === "Prizma") {
            producttype?.includes("Prizma") ? setproducttype(producttype?.filter(item => item !== "Prizma")) : setproducttype([...producttype, "Prizma"])
        }
    }
    const getproduct = async () => {
        try {
            await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetAllProduct`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },
            }).then((res) => res.json()).then((response) => {


                if (response.status === 200) {

                    const uniqueColors = new Set();
                    const helixfilteredData = response.data.filter((item) => {
                        if (item.ProductCategory === "Helix" && item.ProductFor !== "Dark Edition"  && !uniqueColors.has(item.ProductColorCode)) {
                            uniqueColors.add(item.ProductColorCode);
                            return true;
                        }
                        return false;
                    });
                    setactivecolor(helixfilteredData[2]?.ProductColorCode)
                    setactivetype("Helix")
                    setactiveproduct(helixfilteredData[2])
                    for (let i = 0; i < helixfilteredData.length; i++) {
                        if (helixfilteredData[i].ProductColorCode === "#000000") {
                            colorlist[0].available = true
                        }
                        if (helixfilteredData[i].ProductColorCode === "#6d4d33") {
                            colorlist[1].available = true
                        }
                        if (helixfilteredData[i].ProductColorCode === "#535353") {
                            colorlist[2].available = true
                        }
                        if (helixfilteredData[i].ProductColorCode === "#d8d8d8") {
                            colorlist[3].available = true
                        }
                        if (helixfilteredData[i].ProductColorCode === "#6d6d6d") {
                            colorlist[4].available = true
                        }
                        if (helixfilteredData[i].ProductColorCode === "#F0F0F0") {
                            colorlist[5].available = true
                        }
                        if (helixfilteredData[i].ProductColorCode === "#A47141") {
                            colorlist[6].available = true
                        }
                        if (helixfilteredData[i].ProductColorCode === "#4A2413") {
                            colorlist[7].available = true
                        }
                        if (helixfilteredData[i].ProductColorCode === "#F0F0F1") {
                            colorlist[8].available = true
                        }
                        if (helixfilteredData[i].ProductColorCode === "#8E8E8E") {
                            colorlist[9].available = true
                        }
                    }
                    setcolorlist(colorlist)

                    sethelixList(helixfilteredData)
                    const knoxxuniqueColors = new Set();
                    const knoxxfilteredData = response.data.filter((item) => {
                        if (item.ProductCategory === "Knoxx"&& item.ProductFor !== "Dark Edition" && !knoxxuniqueColors.has(item.ProductColorCode)) {
                            knoxxuniqueColors.add(item.ProductColorCode);
                            return true;
                        }
                        return false;
                    });
                    setknoxxList(knoxxfilteredData)
                    for (let i = 0; i < knoxxfilteredData.length; i++) {
                        if (knoxxfilteredData[i].ProductColorCode === "#000000") {
                            knoxxcolorlist[0].available = true
                        }
                        if (knoxxfilteredData[i].ProductColorCode === "#6d4d33") {
                            knoxxcolorlist[1].available = true
                        }
                        if (knoxxfilteredData[i].ProductColorCode === "#535353") {
                            knoxxcolorlist[2].available = true
                        }
                        if (knoxxfilteredData[i].ProductColorCode === "#d8d8d8") {
                            knoxxcolorlist[3].available = true
                        }
                        if (knoxxfilteredData[i].ProductColorCode === "#6d6d6d") {
                            knoxxcolorlist[4].available = true
                        }
                        if (knoxxfilteredData[i].ProductColorCode === "#F0F0F0") {
                            knoxxcolorlist[5].available = true
                        }
                        if (knoxxfilteredData[i].ProductColorCode === "#A47141") {
                            knoxxcolorlist[6].available = true
                        }
                        if (knoxxfilteredData[i].ProductColorCode === "#4A2413") {
                            knoxxcolorlist[7].available = true
                        }
                        if (knoxxfilteredData[i].ProductColorCode === "#F0F0F1") {
                            knoxxcolorlist[8].available = true
                        }
                        if (knoxxfilteredData[i].ProductColorCode === "#8E8E8E") {
                            knoxxcolorlist[9].available = true
                        }
                    }
                    setknoxxcolorlist(knoxxcolorlist)
                    setknoxxactiveproduct(knoxxfilteredData[0])
                    setknoxxactivecolor(knoxxfilteredData[0]?.ProductColorCode)
                    setknoxxactivetype("Knoxx")
                    const stellauniqueColors = new Set();
                    const stellafilteredData = response.data.filter((item) => {
                        if (item.ProductCategory === "Stella"&& item.ProductFor !== "Dark Edition" && !stellauniqueColors.has(item.ProductColorCode)) {
                            stellauniqueColors.add(item.ProductColorCode);
                            return true;
                        }
                        return false;
                    });
                    setstellaList(stellafilteredData)
                    for (let i = 0; i < stellafilteredData.length; i++) {
                        if (stellafilteredData[i].ProductColorCode === "#000000") {
                            stellacolorlist[0].available = true
                        }
                        if (stellafilteredData[i].ProductColorCode === "#6d4d33") {
                            stellacolorlist[1].available = true
                        }
                        if (stellafilteredData[i].ProductColorCode === "#535353") {
                            stellacolorlist[2].available = true
                        }
                        if (stellafilteredData[i].ProductColorCode === "#d8d8d8") {
                            stellacolorlist[3].available = true
                        }
                        if (stellafilteredData[i].ProductColorCode === "#6d6d6d") {
                            stellacolorlist[4].available = true
                        }
                        if (stellafilteredData[i].ProductColorCode === "#F0F0F0") {
                            stellacolorlist[5].available = true
                        }
                        if (stellafilteredData[i].ProductColorCode === "#A47141") {
                            stellacolorlist[6].available = true
                        }
                        if (stellafilteredData[i].ProductColorCode === "#4A2413") {
                            stellacolorlist[7].available = true
                        }
                        if (stellafilteredData[i].ProductColorCode === "#F0F0F1") {
                            stellacolorlist[8].available = true
                        }
                        if (stellafilteredData[i].ProductColorCode === "#8E8E8E") {
                            stellacolorlist[9].available = true
                        }
                    }
                    setstellacolorlist(stellacolorlist)

                    setstellaactiveproduct(stellafilteredData[3])
                    setstellaactivecolor(stellafilteredData[3]?.ProductColorCode)
                    setstellaactivetype("Stella")
                    const knoxxquaduniqueColors = new Set();
                    const knoxxQuadfilteredData = response.data.filter((item) => {
                        if (item.ProductCategory === "Knoxx-Quad" && item.ProductFor !== "Dark Edition"&& !knoxxquaduniqueColors.has(item.ProductColorCode)) {
                            knoxxquaduniqueColors.add(item.ProductColorCode);
                            return true;
                        }
                        return false;
                    });
                    setknoxxQuadList(knoxxQuadfilteredData)
                    for (let i = 0; i < knoxxQuadfilteredData.length; i++) {
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#000000") {
                            knoxxquadcolorlist[0].available = true
                        }
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#6d4d33") {
                            knoxxquadcolorlist[1].available = true
                        }
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#535353") {
                            knoxxquadcolorlist[2].available = true
                        }
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#d8d8d8") {
                            knoxxquadcolorlist[3].available = true
                        }
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#6d6d6d") {
                            knoxxquadcolorlist[4].available = true
                        }
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#F0F0F0") {
                            knoxxquadcolorlist[5].available = true
                        }
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#A47141") {
                            knoxxquadcolorlist[6].available = true
                        }
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#4A2413") {
                            knoxxquadcolorlist[7].available = true
                        }
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#F0F0F1") {
                            knoxxquadcolorlist[8].available = true
                        }
                        if (knoxxQuadfilteredData[i].ProductColorCode === "#8E8E8E") {
                            knoxxquadcolorlist[9].available = true
                        }
                    }
                    setknoxxquadcolorlist(knoxxquadcolorlist)
                    setknoxxQuadactiveproduct(knoxxQuadfilteredData[4])
                    setknoxxQuadactivecolor(knoxxQuadfilteredData[4]?.ProductColorCode)
                    setknoxxQuadactivetype("Knoxx-Quad")
                    const stellaquaduniqueColors = new Set();

                    const stellaQuadfilteredData = response.data.filter((item) => {
                        if (item.ProductCategory === "Stella-Quad" && item.ProductFor !== "Dark Edition" && !stellaquaduniqueColors.has(item.ProductColorCode)) {
                            stellaquaduniqueColors.add(item.ProductColorCode);
                            return true;
                        }
                        return false;
                    });
                    
                    setstellaQuadList(stellaQuadfilteredData)
                    for (let i = 0; i < stellaQuadfilteredData.length; i++) {
                        if (stellaQuadfilteredData[i].ProductColorCode === "#000000") {
                            stellaquadcolorlist[0].available = true
                        }
                        if (stellaQuadfilteredData[i].ProductColorCode === "#6d4d33") {
                            stellaquadcolorlist[1].available = true
                        }
                        if (stellaQuadfilteredData[i].ProductColorCode === "#535353") {
                            stellaquadcolorlist[2].available = true
                        }
                        if (stellaQuadfilteredData[i].ProductColorCode === "#d8d8d8") {
                            stellaquadcolorlist[3].available = true
                        }
                        if (stellaQuadfilteredData[i].ProductColorCode === "#6d6d6d") {
                            stellaquadcolorlist[4].available = true
                        }
                        if (stellaQuadfilteredData[i].ProductColorCode === "#F0F0F0") {
                            stellaquadcolorlist[5].available = true
                        }
                        if (stellaQuadfilteredData[i].ProductColorCode === "#A47141") {
                            stellaquadcolorlist[6].available = true
                        }
                        if (stellaQuadfilteredData[i].ProductColorCode === "#4A2413") {
                            stellaquadcolorlist[7].available = true
                        }
                        if (stellaQuadfilteredData[i].ProductColorCode === "#F0F0F1") {
                            stellaquadcolorlist[8].available = true
                        }
                        if (stellaQuadfilteredData[i].ProductColorCode === "#8E8E8E") {
                            stellaquadcolorlist[9].available = true
                        }
                    }
                    setstellaquadcolorlist(stellaquadcolorlist)
                    setstellaQuadactiveproduct(stellaQuadfilteredData[4])
                    setstellaQuadactivecolor(stellaQuadfilteredData[4]?.ProductColorCode)
                    setstellaQuadactivetype("Stella-Quad")
                    const prizmauniqueColors = new Set();
                    const prizmafilteredData = response.data.filter((item) => {
                        if (item.ProductCategory === "Prizma"&& item.ProductFor !== "Dark Edition" && !prizmauniqueColors.has(item.ProductColorCode)) {
                            prizmauniqueColors.add(item.ProductColorCode);
                            return true;
                        }
                        return false;
                    });
                    setprizmaList(prizmafilteredData)
                    for (let i = 0; i < prizmafilteredData.length; i++) {
                        if (prizmafilteredData[i].ProductColorCode === "#000000") {
                            prizmacolorlist[0].available = true
                        }
                        if (prizmafilteredData[i].ProductColorCode === "#6d4d33") {
                            prizmacolorlist[1].available = true
                        }
                        if (prizmafilteredData[i].ProductColorCode === "#535353") {
                            prizmacolorlist[2].available = true
                        }
                        if (prizmafilteredData[i].ProductColorCode === "#d8d8d8") {
                            prizmacolorlist[3].available = true
                        }
                        if (prizmafilteredData[i].ProductColorCode === "#6d6d6d") {
                            prizmacolorlist[4].available = true
                        }
                        if (prizmafilteredData[i].ProductColorCode === "#F0F0F0") {
                            prizmacolorlist[5].available = true
                        }
                        if (prizmafilteredData[i].ProductColorCode === "#A47141") {
                            prizmacolorlist[6].available = true
                        }
                        if (prizmafilteredData[i].ProductColorCode === "#4A2413") {
                            prizmacolorlist[7].available = true
                        }
                        if (prizmafilteredData[i].ProductColorCode === "#F0F0F1") {
                            prizmacolorlist[8].available = true
                        }
                        if (prizmafilteredData[i].ProductColorCode === "#8E8E8E") {
                            prizmacolorlist[9].available = true
                        }
                    }
                    setprizmacolorlist(prizmacolorlist)
                    setprizmaactiveproduct(prizmafilteredData[0])

                    setprizmaactivecolor(prizmafilteredData[0]?.ProductColorCode)
                    setprizmaactivetype("Prizma")
                    setShowLoader(false)
                } else {
                    setShowLoader(false)
                }
            }
            )
        } catch (error) {
            console.log(error)
            setShowLoader(false)
        }
    }
    const getActiveColorName = (colorcode) => {
        const activeColorObject = colorlist.find(color => color.color === colorcode);
        return activeColorObject ? activeColorObject.value : "";
    };
    const getActiveProduct = (type, colorcode) => {
        if (type === "Helix") {
            const activeProductObject = helixList?.find(product => product.ProductColorCode === colorcode);
            setactiveproduct(activeProductObject)
        } else if (type === "Knoxx") {
            const activeProductObject = knoxxList?.find(product => product.ProductColorCode === colorcode);
            console.log(activeProductObject)
            setknoxxactiveproduct(activeProductObject)
        } else if (type === "Stella") {
            const activeProductObject = stellaList?.find(product => product.ProductColorCode === colorcode);
            setstellaactiveproduct(activeProductObject)
        } else if (type === "Knoxx-Quad") {
            const activeProductObject = knoxxQuadList?.find(product => product.ProductColorCode === colorcode);
            setknoxxQuadactiveproduct(activeProductObject)
        } else if (type === "Stella-Quad") {
            const activeProductObject = stellaQuadList?.find(product => product.ProductColorCode === colorcode);
            setstellaQuadactiveproduct(activeProductObject)
        } else if (type === "Prizma") {
            const activeProductObject = prizmaList?.find(product => product.ProductColorCode === colorcode);
            setprizmaactiveproduct(activeProductObject)
        }


    }
    const getActiveColorNameknoxx = (colorcode) => {

        colortype?.includes(colorcode) ? setcolortype(colortype?.filter(item => item !== colorcode)) : setcolortype([...colortype, colorcode])
    }
    const prizmasettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        arrow: false,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToScroll: 1,
        className: "prizmaslidermain",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            }
        ]

    }

    const [showMore, setShowMore] = useState(false)

    return (
        showLoader ? <>
            <section className="mt-5 pt-4 loader-align">
                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loader-gif.gif" alt="loader"></img>
                <p>LOADING COLORBOT...</p>
            </section>
        </>
            :
            <section className="mt-5 pt-4">
                <ProductHead />




                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="100000">

                    {/* <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div> */}

                    <div className="carousel-inner">

                        <div className="carousel-item active">

                            <LazyLoadImage src={window.innerWidth < 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-coupon-mob.webp" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-coupon.webp"}
                                width={"100vw"}
                                height={"100%"}
                                className="d-block  img-fluid" alt="product category banner 1"
                                effect="blur"
                                fetchpriority="high"
                                loading="eager"
                            />

                        </div>

                        {/* <div className="carousel-item">

                            <LazyLoadImage src={window.innerWidth < 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-new2-mob.webp" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-new2.webp"}
                                width={"100vw"}
                                className="d-block w-100 img-fluid" alt="product category banner 2"
                                effect="blur"
                            />

                        </div>
                        <div className="carousel-item">

                            <LazyLoadImage src={window.innerWidth < 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-new3-mob.webp" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-new3.webp"}
                                width={"100vw"}
                                className="d-block w-100 img-fluid" alt="product category banner 3"
                                effect="blur"
                            />

                        </div> */}

                    </div>

                    {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>

                    <button
                        className="carousel-control-next"
                        type="button"
                        id="next-slide"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                        ref={nextSlideButtonRef}
                    >
                        <span className="carousel-control-next-icon" aria-hidden={true}></span>
                        <span className="visually-hidden">Next</span>
                    </button> */}

                </div>

                <div className="container-fluid pad-categ">

                    <p className="breadcrumb"> <a style={{ color: "#000000" }} href="/" > Home </a> &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <span style={{ color: "#1428A1", fontSize: "13px" }}>BLDC Ceiling Fans</span></p>
                    <h1 className="SEO-text" >BLDC Ceiling Fans</h1>
                    <div className="row">
                        {width > 1000 ? <div className="col-lg-3">
                            <div className="filter">
                                {/* <div id="sortby">
                                    <div className="title">
                                        Sort by
                                    </div>

                                    <div className="form-check mt-2">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label className="form-check-label labeltitle" htmlFor="flexRadioDefault2">
                                            Relevance
                                        </label>
                                    </div>
                                    <div className="form-check mt-0">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                        <label className="form-check-label labeltitle" htmlFor="flexRadioDefault3">
                                            Price (Low to High)
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                        <label className="form-check-label labeltitle" htmlFor="flexRadioDefault4">
                                            Price (High to Low)
                                        </label>
                                    </div>
                                </div> */}
                                <br />
                                <div id="product">
                                    <div className="title">
                                        Products
                                    </div>
                                    <div className="form-check mt-2">
                                        <input className="form-check-input" type="checkbox" value="Helix" id="flexCheckDefault" onClick={() => {
                                            updateproducttype("Helix")

                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault" >
                                            Helix
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Knoxx" id="flexCheckDefault1" onClick={() => {
                                            updateproducttype("Knoxx")
                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault1">
                                            Knoxx
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Stella" id="flexCheckDefault2" onClick={() => {
                                            updateproducttype("Stella")
                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault2">
                                            Stella
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Knoxx-Quad" id="flexCheckDefault3" onClick={() => {
                                            updateproducttype("Knoxx-Quad")
                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault3">
                                            Knoxx Quad
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Stella-Quad" id="flexCheckDefault4" onClick={() => {
                                            updateproducttype("Stella-Quad")
                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault4">
                                            Stella Quad
                                        </label>
                                    </div>
                                    {/* <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Prizma" id="flexCheckDefault5" onClick={() => {
                                            updateproducttype("Prizma")
                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault5">
                                            Prizma
                                        </label>
                                    </div> */}
                                </div>
                                <br />
                                <div id="color">
                                    <div className="title">
                                        Colors
                                    </div>
                                    {colorlist.map((item, index) => {
                                        return (
                                            <div className="form-check mt-2" key={index}>
                                                <input className="form-check-input" type="checkbox" value="" id={`flexCheckDefaultcolor${index}`} onClick={() => {
                                                    getActiveColorNameknoxx(item.color)

                                                }} />
                                                <label className="form-check-label labeltitle" htmlFor={`flexCheckDefaultcolor${index}`}>
                                                    {item.value}
                                                </label>
                                            </div>
                                        )
                                    })}


                                </div>
                                <br />
                                <div id="types">
                                    <div className="title">
                                        Types
                                    </div>

                                    {/* <div className="form-check mt-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaulttype" />
                                    <label className="form-check-label labeltitle" htmlFor="flexCheckDefaulttype">
                                        With remote
                                    </label>
                                </div> */}

                                    {/* <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1type" />
                                    <label className="form-check-label labeltitle" htmlFor="flexCheckDefault1type">
                                        Without remote
                                    </label>
                                </div> */}

                                    <div className="form-check mt-2">
                                        <input className="form-check-input" type="checkbox" value="with_led" id="flexCheckDefault2type" onChange={(e) => {
                                            if (e.target.checked) {
                                                setcheckLed((prev) => [...prev, "with_led"])
                                            } else {
                                                setcheckLed((prev) => prev.filter(item => item !== "with_led"))
                                            }

                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault2type">
                                            With LED
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="without_led" id="flexCheckDefault3type" onChange={(e) => {
                                            if (e.target.checked) {
                                                setcheckLed((prev) => [...prev, "without_led"])
                                            } else {
                                                setcheckLed((prev) => prev.filter(item => item !== "without_led"))
                                            }


                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault3type">
                                            Without LED
                                        </label>
                                    </div>

                                </div>
                                <br />
                                {/* <div id="availability">
                                <div className="title">
                                    Availability
                                </div>

                                <div className="form-check mt-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefaultavailability" />
                                    <label className="form-check-label labeltitle" htmlFor="flexRadioDefaultavailability">
                                        Offers
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefaultavailability1" />
                                    <label className="form-check-label labeltitle" htmlFor="flexRadioDefaultavailability1">
                                        Latest
                                    </label>
                                </div>

                            </div> */}
                                <br />
                                <div id="price">
                                    <div className="title">
                                        Price
                                    </div>
                                    <div className="form-check mt-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultprice" onChange={(e) => {
                                            if (e.target.checked) {
                                                setpriceArray([...priceArray, {
                                                    firstprice: 2000,
                                                    secondprice: 3000
                                                }])
                                            } else {
                                                setpriceArray(priceArray?.filter(price => !(price.firstprice === 2000 && price.secondprice === 3000)));
                                            }
                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefaultprice">
                                            <i className="fa fa-inr" aria-hidden="true"></i>2000 - <i className="fa fa-inr" aria-hidden="true"></i>3000
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1price" onChange={(e) => {
                                            if (e.target.checked) {
                                                setpriceArray([...priceArray, {
                                                    firstprice: 3000,
                                                    secondprice: 4000

                                                }])
                                            } else {
                                                setpriceArray(priceArray?.filter(price => !(price.firstprice === 3000 && price.secondprice === 4000)));
                                            }

                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault1price">
                                            <i className="fa fa-inr" aria-hidden="true"></i>3000 - <i className="fa fa-inr" aria-hidden="true"></i>4000
                                        </label>
                                    </div>
                                    <div className="form-check mb-5">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2price" onChange={(e) => {
                                            if (e.target.checked) {
                                                setpriceArray([...priceArray, {
                                                    firstprice: 4000,
                                                    secondprice: 5000
                                                }])
                                            } else {
                                                setpriceArray(priceArray?.filter(price => !(price.firstprice === 4000 && price.secondprice === 5000)));
                                            }
                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor="flexCheckDefault2price">
                                            <i className="fa fa-inr" aria-hidden="true"></i>4000 - <i className="fa fa-inr" aria-hidden="true"></i>5000
                                        </label>
                                    </div>
                                    {/* <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault3price" />
                                    <label className="form-check-label labeltitle" htmlFor="flexCheckDefault3price">
                                        <i className="fa fa-inr" aria-hidden="true"></i>5000 - <i className="fa fa-inr" aria-hidden="true"></i>6000
                                    </label>
                                </div> */}

                                </div>
                            </div>
                        </div> : ""}

                        <div className="col-lg-9">
                            <div className="row">

                                {/* <div className="commingsoon-txt" style={{ color: "#000000", fontSize: "30px", margin: "30px 0", textAlign: "center" }}>COMING SOON</div> */}



                                {stellaList?.length > 0 && (producttype?.length === 0 || producttype?.includes("Stella")) ?
                                    colortype?.length === 0 || colortype?.some(item => (
                                        stellaList?.some(product => product.ProductColorCode === item)
                                    )) ?
                                        checkLed?.length === 0 || checkLed?.includes("with_led") ?
                                            priceArray?.length === 0 || priceArray?.some(item => (
                                                stellaList?.some(product => product.ProductSelling >= item.firstprice && product.ProductSelling <= item.secondprice)
                                            )) ?
                                                stellaList.length > 0 ? <div className="col-lg-4 col-md-6">

                                                    <div className="bg-w">
                                                        <NavLink to="/colorbot-stella">  <div className="pro-img">

                                                            {/* <img src={stellaactiveproduct?.Productthumbnail}
                                                                width={"100%"}
                                                                className=" img-fluid lazyimg product-max-height" alt="stella-BLDC-fan-img"
                                                                effect="blur"
                                                            /> */}


                                                            <LazyLoadImage src={stellaactiveproduct?.Productthumbnail}
                                                                width={"100%"}
                                                                className=" img-fluid lazyimg product-max-height" alt="stella-BLDC-fan-img"
                                                                effect="blur"
                                                                onClick={() => {
                                                                    localStorage.setItem("activeColor", stellaactivecolor)
                                                                    localStorage.setItem("activeType", "Stella")
                                                                }}
                                                            />

                                                        </div>stella BLDC fan</NavLink>

                                                        <div className="box-txt">

                                                            <div className="helixcolorlist">
                                                                {stellacolorlist.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            {item.available ? <div
                                                                                className={item.color === stellaactivecolor && stellaactivetype === "Stella" ? "clr1 listactive helixcolorlistitem helixcoloractive" : "clr1  helixcolorlistitem"}
                                                                                key={index}
                                                                                data-hover={getActiveColorName(item.color)}
                                                                                onClick={() => {
                                                                                    if (item.available) {
                                                                                        localStorage.setItem("activeColor", item.color)
                                                                                        localStorage.setItem("activeType", "Stella")
                                                                                        setstellaactivecolor(item.color);
                                                                                        setstellaactivetype("Stella")
                                                                                        getActiveProduct("Stella", item.color)

                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    transform: "rotate(90deg)",
                                                                                    background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                                                    pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                                                    opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                                                                }}
                                                                            >
                                                                                <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                                                                </div>
                                                                            </div> : ""}
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>

                                                            {/* <h5>{stellaactiveproduct?.ProductName}</h5> */}

                                                            <div className="cate-product-name">Stella BLDC Fan</div>

                                                            <div className="price-btn">
                                                                <div className="price">
                                                                    <p><i className="fa fa-inr" aria-hidden="true"></i> {stellaactiveproduct?.ProductSelling} <span>-{(((stellaactiveproduct?.ProductPrice - stellaactiveproduct?.ProductSelling) / stellaactiveproduct?.ProductPrice) * 100).toFixed(0)}%</span></p>
                                                                    <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>{stellaactiveproduct?.ProductPrice}</del> </p>
                                                                </div>

                                                                <NavLink to="/colorbot-stella"> <div className="buy-btn"><button className="btn" onClick={() => {
                                                                    localStorage.setItem("activeColor", stellaactivecolor)
                                                                    localStorage.setItem("activeType", "Stella")
                                                                }}>Buy Now</button></div></NavLink>

                                                            </div>

                                                        </div>

                                                    </div>



                                                </div> : "" : "" : "" : "" : ""}



                                {knoxxList?.length > 0 && (producttype?.length === 0 || producttype?.includes("Knoxx")) ?
                                    colortype?.length === 0 || colortype?.some(item => (
                                        knoxxList?.some(product => product.ProductColorCode === item)
                                    )) ?
                                        checkLed?.length === 0 || checkLed?.includes("with_led") ?
                                            priceArray?.length === 0 || priceArray?.some(item => (
                                                knoxxList?.some(product => product.ProductSelling >= item.firstprice && product.ProductSelling <= item.secondprice)
                                            )) ?
                                                knoxxList.length > 0 ? <div className="col-lg-4 col-md-6">

                                                    <div className="bg-w">
                                                        <NavLink to="/colorbot-knoxx">   <div className="pro-img">

                                                            {/* <img src={knoxxactiveproduct?.Productthumbnail}
                                                                width={"100%"}
                                                                className=" img-fluid lazyimg product-max-height" alt="knoxx-BLDC-fan-img"
                                                                effect="blur"
                                                            /> */}


                                                            <LazyLoadImage src={knoxxactiveproduct?.Productthumbnail}
                                                                width={"100%"}
                                                                className=" img-fluid lazyimg product-max-height" alt="knoxx-BLDC-fan-img"
                                                                effect="blur"
                                                                onClick={() => {
                                                                    localStorage.setItem("activeColor", knoxxactivecolor)
                                                                    localStorage.setItem("activeType", "Knoxx")
                                                                }}
                                                            />

                                                        </div>knoxx BLDC fan</NavLink>

                                                        <div className="box-txt">

                                                            <div className="helixcolorlist">
                                                                {knoxxcolorlist.map((item, index) => {
                                                                    return (

                                                                        <>  {item.available ? <div
                                                                            className={item.color === knoxxactivecolor && knoxxactivetype === "Knoxx" ? "clr1 listactive helixcolorlistitem helixcoloractive" : "clr1  helixcolorlistitem"}
                                                                            key={index}
                                                                            data-hover={getActiveColorName(item.color)}
                                                                            onClick={() => {
                                                                                if (item.available) {
                                                                                    localStorage.setItem("activeColor", item.color)
                                                                                    localStorage.setItem("activeType", "Knoxx")
                                                                                    setknoxxactivecolor(item.color);
                                                                                    setknoxxactivetype("Knoxx")
                                                                                    getActiveProduct("Knoxx", item.color)
                                                                                }
                                                                            }}
                                                                            style={{
                                                                                padding: "10px",
                                                                                transform: "rotate(90deg)",
                                                                                background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                                                pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                                                opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                                                            }}
                                                                        >
                                                                            <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                                                            </div>
                                                                        </div> : ""} </>
                                                                    )
                                                                })}
                                                            </div>

                                                            {/* <h5>{knoxxactiveproduct?.ProductName}</h5> */}

                                                            <div className="cate-product-name">Knoxx BLDC Fan</div>

                                                            <div className="price-btn">
                                                                <div className="price">
                                                                    <p><i className="fa fa-inr" aria-hidden="true"></i> {knoxxactiveproduct?.ProductSelling} <span>-{(((knoxxactiveproduct?.ProductPrice - knoxxactiveproduct?.ProductSelling) / knoxxactiveproduct?.ProductPrice) * 100).toFixed(0)}%</span></p>
                                                                    <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>{knoxxactiveproduct?.ProductPrice}</del> </p>
                                                                </div>
                                                                <NavLink to="/colorbot-knoxx"><div className="buy-btn"><button className="btn" onClick={() => {
                                                                    localStorage.setItem("activeColor", knoxxactivecolor)
                                                                    localStorage.setItem("activeType", "Knoxx")
                                                                }}>Buy Now</button></div></NavLink>
                                                            </div>

                                                        </div>

                                                    </div>



                                                </div> : "" : "" : "" : "" : ""}



                                {stellaQuadList?.length > 0 && (producttype?.length === 0 || producttype?.includes("Stella-Quad")) ?

                                    colortype?.length === 0 || colortype?.some(item => (
                                        stellaQuadList?.some(product => product.ProductColorCode === item)
                                    )) ?
                                        checkLed?.length === 0 || checkLed?.includes("with_led") ?
                                            priceArray?.length === 0 || priceArray?.some(item => (
                                                stellaQuadList?.some(product => product.ProductSelling >= item.firstprice && product.ProductSelling <= item.secondprice)
                                            )) ?
                                                stellaQuadList.length > 0 ? <div className="col-lg-4 col-md-6">

                                                    <div className="bg-w">
                                                        <NavLink to="/colorbot-stella-quad">  <div className="pro-img">

                                                            {/* <img src={stellaQuadactiveproduct?.Productthumbnail}
                            width={"100%"}
                            className=" img-fluid lazyimg product-max-height" alt="stella-quad-BLDC-fan-img"
                            effect="blur"
                        /> */}


                                                            <LazyLoadImage src={stellaQuadactiveproduct?.Productthumbnail}
                                                                width={"100%"}
                                                                className=" img-fluid lazyimg product-max-height" alt="stella-quad-BLDC-fan-img"
                                                                effect="blur"
                                                                onClick={() => {
                                                                    localStorage.setItem("activeColor", stellaQuadactivecolor)
                                                                    localStorage.setItem("activeType", "Stella-Quad")
                                                                }}
                                                            />

                                                        </div>stella quad BLDC fan</NavLink>

                                                        <div className="box-txt">

                                                            <div className="helixcolorlist">
                                                                {stellaquadcolorlist.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            {item.available ? <div
                                                                                className={item.color === stellaQuadactivecolor && stellaQuadactivetype === "Stella-Quad" ? "clr1 listactive helixcolorlistitem helixcoloractive" : "clr1  helixcolorlistitem"}
                                                                                key={index}
                                                                                data-hover={getActiveColorName(item.color)}
                                                                                onClick={() => {
                                                                                    if (item.available) {
                                                                                        localStorage.setItem("activeColor", item.color)
                                                                                        localStorage.setItem("activeType", "Stella-Quad")
                                                                                        setstellaQuadactivecolor(item.color);
                                                                                        setstellaQuadactivetype("Stella-Quad")
                                                                                        getActiveProduct("Stella-Quad", item.color)

                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    transform: "rotate(90deg)",
                                                                                    background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                                                    pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                                                    opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                                                                }}
                                                                            >
                                                                                <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                                                                </div>
                                                                            </div> : ""}</>
                                                                    )
                                                                })}
                                                            </div>

                                                            {/* <h5>{stellaQuadactiveproduct?.ProductName}</h5> */}

                                                            <div className="cate-product-name">Stella Quad BLDC Fan</div>

                                                            <div className="price-btn">
                                                                <div className="price">
                                                                    <p><i className="fa fa-inr" aria-hidden="true"></i> {stellaQuadactiveproduct?.ProductSelling} <span>-{(((stellaQuadactiveproduct?.ProductPrice - stellaQuadactiveproduct?.ProductSelling) / stellaQuadactiveproduct?.ProductPrice) * 100).toFixed(0)}%</span></p>
                                                                    <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>{stellaQuadactiveproduct?.ProductPrice}</del> </p>
                                                                </div>
                                                                <NavLink to="/colorbot-stella-quad">   <div className="buy-btn"><button className="btn" onClick={() => {
                                                                    localStorage.setItem("activeColor", stellaQuadactivecolor)
                                                                    localStorage.setItem("activeType", "Stella-Quad")
                                                                }}>Buy Now</button></div></NavLink>
                                                            </div>

                                                        </div>

                                                    </div>



                                                </div> : "" : "" : "" : "" : ""}





                                {knoxxQuadList?.length > 0 && (producttype?.length === 0 || producttype?.includes("Knoxx-Quad")) ?
                                    colortype?.length === 0 || colortype?.some(item => (
                                        knoxxQuadList?.some(product => product.ProductColorCode === item)
                                    )) ?
                                        checkLed?.length === 0 || checkLed?.includes("with_led") ?
                                            priceArray?.length === 0 || priceArray?.some(item => (
                                                knoxxQuadList?.some(product => product.ProductSelling >= item.firstprice && product.ProductSelling <= item.secondprice)
                                            )) ?
                                                knoxxQuadList.length > 0 ? <div className="col-lg-4 col-md-6">

                                                    <div className="bg-w">
                                                        <NavLink to="/colorbot-knoxx-quad"> <div className="pro-img">

                                                            {/* <img src={knoxxQuadactiveproduct?.Productthumbnail}
                                                                width={"100%"}
                                                                className=" img-fluid lazyimg product-max-height" alt="knoxx-quad-BLDC-fan-img"
                                                                effect="blur"
                                                            /> */}


                                                            <LazyLoadImage src={knoxxQuadactiveproduct?.Productthumbnail}
                                                                width={"100%"}
                                                                className=" img-fluid lazyimg product-max-height" alt="knoxx-quad-BLDC-fan-img"
                                                                effect="blur"
                                                                onClick={() => {
                                                                    localStorage.setItem("activeColor", knoxxQuadactivecolor)
                                                                    localStorage.setItem("activeType", "Knoxx-Quad")
                                                                }}
                                                            />

                                                        </div>knoxx quad BLDC fan</NavLink>

                                                        <div className="box-txt">

                                                            <div className="helixcolorlist">
                                                                {knoxxquadcolorlist.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            {item.available ? <div
                                                                                className={item.color === knoxxQuadactivecolor && knoxxQuadactivetype === "Knoxx-Quad" ? "clr1 listactive helixcolorlistitem helixcoloractive" : "clr1  helixcolorlistitem"}
                                                                                key={index}
                                                                                data-hover={getActiveColorName(item.color)}
                                                                                onClick={() => {
                                                                                    if (item.available) {
                                                                                        localStorage.setItem("activeColor", item.color)
                                                                                        localStorage.setItem("activeType", "Knoxx-Quad")
                                                                                        setknoxxQuadactivecolor(item.color);
                                                                                        setknoxxQuadactivetype("Knoxx-Quad")
                                                                                        getActiveProduct("Knoxx-Quad", item.color)

                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    transform: "rotate(90deg)",
                                                                                    background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                                                    pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                                                    opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                                                                }}
                                                                            >
                                                                                <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                                                                </div>
                                                                            </div> : ""}
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>

                                                            {/* <h5>{knoxxQuadactiveproduct?.ProductName}</h5> */}


                                                            <div className="cate-product-name">Knoxx Quad BLDC Fan</div>

                                                            <div className="price-btn">
                                                                <div className="price">
                                                                    <p><i className="fa fa-inr" aria-hidden="true"></i> {knoxxQuadactiveproduct?.ProductSelling} <span>-{(((knoxxQuadactiveproduct?.ProductPrice - knoxxQuadactiveproduct?.ProductSelling) / knoxxQuadactiveproduct?.ProductPrice) * 100).toFixed(0)}%</span></p>
                                                                    <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>{knoxxQuadactiveproduct?.ProductPrice}</del> </p>
                                                                </div>
                                                                <NavLink to="/colorbot-knoxx-quad">   <div className="buy-btn"><button className="btn" onClick={() => {
                                                                    localStorage.setItem("activeColor", knoxxQuadactivecolor)
                                                                    localStorage.setItem("activeType", "Knoxx-Quad")
                                                                }}>Buy Now</button></div></NavLink>
                                                            </div>

                                                        </div>

                                                    </div>



                                                </div> : "" : "" : "" : "" : ""}





                                {helixList.length > 0 && (producttype?.length === 0 || producttype?.includes("Helix")) ?
                                    colortype?.length === 0 || colortype?.some(item => (
                                        helixList?.some(product => product.ProductColorCode === item)

                                    )) ?
                                        checkLed?.length === 0 || checkLed?.includes("with_led") ?
                                            priceArray?.length === 0 || priceArray?.some(item => (
                                                helixList?.some(product => product.ProductSelling >= item.firstprice && product.ProductSelling <= item.secondprice)
                                            )) ?
                                                helixList.length > 0 ? <div className="col-lg-4 col-md-6">

                                                    <div className="bg-w">
                                                        <NavLink to="/colorbot-helix">  <div className="pro-img">

                                                            {/* <img src={activeproduct?.Productthumbnail}
                                                                width={"100%"}
                                                                className=" img-fluid lazyimg product-max-height" alt="helix-BLDC-fan-img"
                                                                effect="blur"
                                                            /> */}


                                                            <LazyLoadImage src={activeproduct?.Productthumbnail}
                                                                width={"100%"}
                                                                className=" img-fluid lazyimg product-max-height" alt="helix-BLDC-fan-img"
                                                                effect="blur"
                                                                onClick={() => {
                                                                    localStorage.setItem("activeColor", activecolor)
                                                                    localStorage.setItem("activeType", "Helix")
                                                                }}
                                                            />

                                                        </div>Helix BLDC fan</NavLink>

                                                        <div className="box-txt" key={"01"}>
                                                            <div className="helixcolorlist">
                                                                {colorlist.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            {item.available ? <div
                                                                                className={item.color === activecolor && activetype === "Helix" ? "clr1 listactive helixcolorlistitem helixcoloractive" : "clr1  helixcolorlistitem"}
                                                                                key={index}
                                                                                data-hover={getActiveColorName(item.color)}
                                                                                onClick={() => {
                                                                                    if (item.available) {
                                                                                        localStorage.setItem("activeColor", item.color)
                                                                                        localStorage.setItem("activeType", "Helix")
                                                                                        setactivecolor(item.color);
                                                                                        setactivetype("Helix")
                                                                                        getActiveProduct("Helix", item.color)

                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    transform: "rotate(90deg)",
                                                                                    background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                                                    pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                                                    opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                                                                }}
                                                                            >
                                                                                { }
                                                                                <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                                                                </div>
                                                                            </div> : ""}
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>


                                                            {/* <h5>{activeproduct.ProductName}</h5> */}
                                                            <div className="cate-product-name">Helix BLDC Fan</div>


                                                            <div className="price-btn">
                                                                <div className="price">
                                                                    <p><i className="fa fa-inr" aria-hidden="true"></i> {activeproduct?.ProductSelling} <span>-{(((activeproduct?.ProductPrice - activeproduct?.ProductSelling) / activeproduct?.ProductPrice) * 100).toFixed(0)}%</span></p>
                                                                    <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>{activeproduct?.ProductPrice}</del> </p>
                                                                </div>
                                                                <NavLink to="/colorbot-helix"> <div className="buy-btn"><button className="btn" onClick={() => {
                                                                    localStorage.setItem("activeColor", activecolor)
                                                                    localStorage.setItem("activeType", "Helix")
                                                                }}>Buy Now</button></div></NavLink>
                                                            </div>

                                                        </div>

                                                    </div>



                                                </div> : "" : "" : "" : "" : ""}



                                {prizmaList?.length > 0 && (producttype?.length === 0 || producttype?.includes("Prizma")) ?
                                    colortype?.length === 0 || colortype?.some(item => (
                                        prizmaList?.some(product => product.ProductColorCode === item)
                                    )) ?
                                        checkLed?.length === 0 || checkLed?.includes("without_led") || checkLed?.includes("with_led")

                                            ?
                                            priceArray?.length === 0 || priceArray?.some(item => (
                                                prizmaList?.some(product => product.ProductSelling >= item.firstprice && product.ProductSelling <= item.secondprice)
                                            )) ?
                                                prizmaList.length > 0 ? <div className="col-lg-4 col-md-6">

                                                    <div className="bg-w">
                                                        <NavLink to="/colorbot-prizma">    <div className="pro-img">

                                                            {/* <img src={prizmaactiveproduct?.Productthumbnail}

                                                                className=" img-fluid lazyimg product-max-height" alt="..."
                                                                effect="blur"
                                                            /> */}


                                                            <LazyLoadImage src={prizmaactiveproduct?.Productthumbnail}

                                                                className=" img-fluid lazyimg product-max-height" alt="..."
                                                                effect="blur"
                                                                onClick={() => {
                                                                    localStorage.setItem("activeColor", prizmaactivecolor)
                                                                    localStorage.setItem("activeType", "Prizma")
                                                                }}
                                                            />

                                                        </div></NavLink>

                                                        <div className="box-txt">

                                                            <div className="helixcolorlist">
                                                                {prizmacolorlist.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            {item.available ? <div
                                                                                className={item.color === prizmaactivecolor && prizmaactivetype === "Prizma" ? "clr1 listactive helixcolorlistitem helixcoloractive" : "clr1  helixcolorlistitem"}
                                                                                key={index}
                                                                                data-hover={getActiveColorName(item.color)}
                                                                                onClick={() => {
                                                                                    if (item.available) {
                                                                                        localStorage.setItem("activeColor", item.color)
                                                                                        localStorage.setItem("activeType", "Prizma")
                                                                                        setprizmaactivecolor(item.color);
                                                                                        setprizmaactivetype("Prizma")
                                                                                        getActiveProduct("Prizma", item.color)
                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    transform: "rotate(90deg)",
                                                                                    background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                                                    pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                                                    opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                                                                }}
                                                                            >
                                                                                <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                                                                </div>
                                                                            </div>
                                                                                : ""}</>
                                                                    )
                                                                })}
                                                            </div>

                                                            {/* <h5>{prizmaactiveproduct?.ProductName}</h5> */}


                                                            <div className="cate-product-name">Prizma BLDC Fan</div>

                                                            <div className="price-btn">
                                                                <div className="price">
                                                                    <p><i className="fa fa-inr" aria-hidden="true"></i> {prizmaactiveproduct?.ProductSelling} <span>-{(((prizmaactiveproduct?.ProductPrice - prizmaactiveproduct?.ProductSelling) / prizmaactiveproduct?.ProductPrice) * 100).toFixed(0)}%</span></p>
                                                                    <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>{prizmaactiveproduct?.ProductPrice}</del> </p>
                                                                </div>
                                                                <NavLink to="/colorbot-prizma"> <div className="buy-btn"><button className="btn" onClick={() => {
                                                                    localStorage.setItem("activeColor", prizmaactivecolor)
                                                                    localStorage.setItem("activeType", "Prizma")
                                                                }}>Buy Now</button></div></NavLink>
                                                            </div>

                                                        </div>

                                                    </div>



                                                </div> : "" : "" : "" : "" : ""}



                                {/* <div className="col-lg-4 col-md-6">

                                <div className="bg-w">
                                    <div className="pro-img">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/fan-soon.svg"}
                                            width={"100%"}
                                            className=" img-fluid lazyimg product-max-height" alt="..."
                                            effect="blur"
                                        />
                                    </div>

                                    <div className="box-txt">

                                        <div className="fan-clr">
                                            <div className="clr1" data-hover="lightgray"></div>
                                            <div className="clr1 clr2" data-hover="black"> </div>
                                            <div className="clr1 clr3 " data-hover="gray"></div>
                                            <div className="clr1 clr4" data-hover="olive"></div>
                                            <div className="clr1 clr5" data-hover="brown"></div>
                                        </div>

                                        <h5>Colorbot Stella</h5>

                                        <div className="price-btn soon">

                                            <div className="buy-btn noti-btn"><button className="btn">Notify Me</button></div>
                                        </div>

                                    </div>

                                </div>



                            </div>
                            <div className="col-lg-4 col-md-6">

                                <div className="bg-w">
                                    <div className="pro-img"> <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/fan-soon.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazyimg product-max-height" alt="..."
                                        effect="blur"
                                    /></div>

                                    <div className="box-txt">

                                        <div className="fan-clr">
                                            <div className="clr1" data-hover="lightgray"></div>
                                            <div className="clr1 clr2" data-hover="black"> </div>
                                            <div className="clr1 clr3 " data-hover="gray"></div>
                                            <div className="clr1 clr4" data-hover="olive"></div>
                                            <div className="clr1 clr5" data-hover="brown"></div>
                                        </div>

                                        <h5>Colorbot Zenek</h5>

                                        <div className="price-btn soon">

                                            <div className="buy-btn noti-btn"><button className="btn">Notify Me</button></div>
                                        </div>

                                    </div>

                                </div>



                            </div>

                            <div className="col-lg-4 col-md-6">

                                <div className="bg-w">
                                    <div className="pro-img"> <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/fan-soon.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazyimg product-max-height" alt="..."
                                        effect="blur"
                                    /></div>

                                    <div className="box-txt">

                                        <div className="fan-clr">
                                            <div className="clr1" data-hover="lightgray"></div>
                                            <div className="clr1 clr2" data-hover="black"> </div>
                                            <div className="clr1 clr3 " data-hover="gray"></div>
                                            <div className="clr1 clr4" data-hover="olive"></div>
                                            <div className="clr1 clr5" data-hover="brown"></div>
                                        </div>

                                        <h5>Colorbot Blitz</h5>

                                        <div className="price-btn soon">

                                            <div className="buy-btn noti-btn"><button className="btn">Notify Me</button></div>
                                        </div>

                                    </div>

                                </div>



                            </div> */}




                            </div>
                        </div>
                    </div>




                </div>

                <div style={{ position: "relative" }}>

                    {/* <img

                        src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/bottom_banner.svg" : "https://colorbots3.s3.ap-south-1.amazonaws.com/banners/dark-moble-banner.jpg"}
                        width={"100vw"}
                        className="d-block w-100 img-fluid" alt="Colorbot BLDC ceiling fan Dark Edition Canopy"
                        effect="blur"

                    /> */}

                    <LazyLoadImage src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/bottom_banner.svg" : "https://colorbots3.s3.ap-south-1.amazonaws.com/banners/dark-moble-banner.jpg"}
                        width={"100vw"}
                        className="d-block w-100 img-fluid" alt="Colorbot BLDC ceiling fan Dark Edition Canopy"
                        effect="blur"
                    />

                    <div className="banner-txt">
                        <h2>BREEZE THROUGH THE <span style={{ color: "#F20000" }}>DARK</span></h2>
                        <h3>Introducing Colorbot Dark Edition BLDC Fans</h3>

                        <NavLink to="/dark">  <button className="btn-explore">Explore Now</button></NavLink>
                    </div>


                </div>
                <div style={{ backgroundColor: "#F5F7FA", padding: "50px 0" }}>
                    <div className="container-fluid">
                        {window.innerWidth > 768 ? <div className="row justify-content-center">

                            <div className="col-lg-3 col-md-12 col-widt">
                                <h2 className="service">
                                    <span><b>Service </b></span>

                                    <span>that makes a </span>
                                    <span><b>Difference</b></span>
                                </h2>
                            </div>
                            <div className="col-lg-3 col-md-6 col-widt">

                                <div className="bg-w serv-card-pad">
                                    <div className="image-align service-img">

                                        {/* <img src={"https://colorbots3.s3.ap-south-1.amazonaws.com/saving.svg"}

                                            className=" img-fluid lazysupportimg" alt="Maximum Saving"
                                            effect="blur"
                                        /> */}


                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/saving.svg"}

                                            className=" img-fluid lazysupportimg" alt="Maximum Saving"
                                            effect="blur"
                                        />

                                    </div>

                                    <div className="box-card">



                                        <h3>Maximum Saving</h3>


                                        <p>Save up to 65%  on bills with an electricity consumption of only 28 watts.</p>

                                    </div>

                                </div>



                            </div>
                            <div className="col-lg-3 col-md-6 col-widt">

                                <div className="bg-w serv-card-pad">
                                    <div className="image-align service-img">

                                        {/* <img src={"https://colorbots3.s3.ap-south-1.amazonaws.com/warranty.svg"}

                                            className=" img-fluid lazysupportimg" alt="Warranty"
                                            effect="blur"
                                        /> */}

                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/warranty.svg"}

                                            className=" img-fluid lazysupportimg" alt="Warranty"
                                            effect="blur"
                                        />
                                    </div>

                                    <div className="box-card">



                                        <h3>Warranty</h3>


                                        <p>On all fans, get 2+2* years of extended Product warranty.</p>

                                    </div>

                                </div>



                            </div>
                            <div className="col-lg-3 col-md-6 col-widt">

                                <div className="bg-w serv-card-pad">
                                    <div className="image-align service-img">

                                        {/* <img src={"https://colorbots3.s3.ap-south-1.amazonaws.com/easy-installation.svg"}

                                            className=" img-fluid lazysupportimg" alt="Easy Installation"
                                            effect="blur"
                                        /> */}


                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/easy-installation.svg"}

                                            className=" img-fluid lazysupportimg" alt="Easy Installation"
                                            effect="blur"
                                        />
                                    </div>

                                    <div className="box-card">



                                        <h3>Easy Installation</h3>


                                        <p>Set it up in a jiffy. Avail of a quick and hassle-free installation.  </p>

                                    </div>

                                </div>



                            </div>
                            <div className="col-lg-3 col-md-6 col-widt">

                                <div className="bg-w serv-card-pad ">
                                    <div className="image-align service-img">

                                        {/* <img src={"https://colorbots3.s3.ap-south-1.amazonaws.com/24-hours-support.svg"}

                                            className=" img-fluid lazysupportimg" alt="Customer Support"
                                            effect="blur"
                                        /> */}

                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/24-hours-support.svg"}

                                            className=" img-fluid lazysupportimg" alt="Customer Support"
                                            effect="blur"
                                        />
                                    </div>

                                    <div className="box-card">



                                        <h3>Customer Support</h3>


                                        <p>24*7, round the clock.
                                            Quick and prompt support, anytime, anywhere. </p>

                                    </div>

                                </div>



                            </div>

                        </div> :
                            <>
                                <div className="row justify-content-center">

                                    <div className="col-lg-3 col-md-12 col-widt">
                                        <h2 className="service">
                                            <span><b>Service </b></span>

                                            <span>that makes a </span>
                                            <span><b>Difference</b></span>
                                        </h2>
                                    </div>
                                </div>
                                <FadeInSection>
                                    <div className="row justify-content-center">
                                        <div className="col-md-12 col-lg-10">


                                            <Slider {...prizmasettings} >
                                                <div className="prizmaslider">
                                                    <div className="bg-w serv-card-pad">
                                                        <div className="image-align service-img">

                                                            {/* <img src={"https://colorbots3.s3.ap-south-1.amazonaws.com/saving.svg"}

                                                                className=" img-fluid lazysupportimg" alt="Maximum Saving"
                                                                effect="blur"
                                                            /> */}

                                                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/saving.svg"}

                                                                className=" img-fluid lazysupportimg" alt="Maximum Saving"
                                                                effect="blur"
                                                            />

                                                        </div>

                                                        <div className="box-card">



                                                            <h3>Maximum Saving</h3>


                                                            <p>Save up to 65%  on bills with an electricity consumption of only 28 watts.</p>

                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="prizmaslider">
                                                    <div className="bg-w serv-card-pad">
                                                        <div className="image-align service-img">

                                                            {/* <img src={"https://colorbots3.s3.ap-south-1.amazonaws.com/warranty.svg"}

                                                                className=" img-fluid lazysupportimg" alt="Warranty"
                                                                effect="blur"
                                                            /> */}

                                                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/warranty.svg"}

                                                                className=" img-fluid lazysupportimg" alt="Warranty"
                                                                effect="blur"
                                                            />
                                                        </div>

                                                        <div className="box-card">



                                                            <h3>Warranty</h3>


                                                            <p>On all fans, get 2+2* years of extended Product warranty.</p>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="prizmaslider">
                                                    <div className="bg-w serv-card-pad">
                                                        <div className="image-align service-img">
                                                            {/* <img src={"https://colorbots3.s3.ap-south-1.amazonaws.com/easy-installation.svg"}

                                                                className=" img-fluid lazysupportimg" alt="Easy Installation"
                                                                effect="blur"

                                                            /> */}

                                                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/easy-installation.svg"}

                                                                className=" img-fluid lazysupportimg" alt="Easy Installation"
                                                                effect="blur"
                                                            />
                                                        </div>

                                                        <div className="box-card">



                                                            <h3>Easy Installation</h3>


                                                            <p>Set it up in a jiffy. Avail of a quick and hassle-free installation.  </p>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="prizmaslider">
                                                    <div className="bg-w serv-card-pad ">
                                                        <div className="image-align service-img">

                                                            {/* <img src={"https://colorbots3.s3.ap-south-1.amazonaws.com/24-hours-support.svg"}

                                                                className=" img-fluid lazysupportimg" alt="Customer Support"
                                                                effect="blur"

                                                            /> */}




                                                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/24-hours-support.svg"}

                                                                className=" img-fluid lazysupportimg" alt="Customer Support"
                                                                effect="blur"
                                                            />
                                                        </div>

                                                        <div className="box-card">



                                                            <h3>Customer Support</h3>


                                                            <p>24*7, round the clock.
                                                                Quick and prompt support, anytime, anywhere. </p>

                                                        </div>

                                                    </div>

                                                </div>




                                            </Slider>




                                        </div>
                                    </div>
                                </FadeInSection>
                            </>
                        }
                    </div>
                </div>
                <br />

                <div className="container">
                    <FadeInSection>
                        <div className="row mt-5 mb-5">
                            <div className="col-md-6">

                                <h2 className="cate-blw-hed">Buy BLDC Ceiling Fans Online in India: Power Saver Fans for Home </h2>
                                <p className="cate-blw-cont" >
                                    Experience the future of cooling this summer with Colorbot BLDC Ceiling fans. Upgrade your home with these stylish remote control fans, designed for ultimate efficiency, aesthetic appeal and unmatched performance.
                                    <br /><br />
                                    At Colorbot, we understand that when it comes to cooling your home or office, you need more than just a fan! Traditional fans consume too much of electricity, create noise and may not complement your modern interiors.  This is where Colorbot fans step in!
                                    <br /><br />
                                    These <NavLink to="/colorbot-Stella"> 5 star ceiling fans</NavLink> are the ultimate power saver of your home while adding a touch of elegance to the space. Integrated with advanced BLDC technology and cutting-edge features, these superior BLDC fans deliver powerful airflow with minimal energy consumption. You can now keep your fans on as much as you want and still be able to cut down your electricity bill to almost 60%!
                                    <br /><br />
                                    It’s time to experience convenient comfort with the wide range of Colorbot 3 and 4 blade BLDC fans. Read on to know what makes them the smartest choice of your home-
                                    <br /><br />

                                </p>

                                <h2 className="cate-blw-cont-h2"> Benefits of Buying Colorbot BLDC Fans</h2>
                                <p className="cate-blw-cont">The latest Colorbot BLDC fans come with a variety of impressive features that serve specific needs and preferences. With modern technology, these fans bring an upgraded modern touch to homes. </p>


                                {showMore ? <>

                                    <h3 className="cate-blw-cont-h3"> Energy Efficiency: </h3>
                                    <p className="cate-blw-cont">Colorbot boasts BEE 5 Star rated BLDC fans for home that are known to be the ultimate power savers. The advanced BLDC technology allows these smart fans to run efficiently without consuming much power. These fans can also run 3 times longer on inverters as they take up only up to 28 watts at maximum speed. </p>

                                    <h3 className="cate-blw-cont-h3"> Powerful Air Delivery: </h3>
                                    <p className="cate-blw-cont">The high speed BLDC fans offer everlasting air with unmatched cooling comfort. With the powerful BLDC motor, these fans deliver adequate air all over the space and ensure effective airflow.</p>

                                    <h3 className="cate-blw-cont-h3"> Noiseless Operation: </h3>
                                    <p className="cate-blw-cont">If you are fed up with your old noisy fans then Colorbot <NavLink to="/colorbot-helix">noiseless BLDC ceiling fans</NavLink>  are the right solution for your home. Buy Colorbot 5 star rated ceiling fans for home and enjoy uninterrupted comfort this summer while they work silently even on highest speed settings.  </p>

                                    <h3 className="cate-blw-cont-h3"> Built to Last: </h3>
                                    <p className="cate-blw-cont">To cope-up with the hot Indian summer, a high-quality fan with great performance is a must. Colorbot presents you the best residential BLDC fans with 100% copper winding, that assures you of long-lasting stable and wobble-free performance. The double ball bearing motor prevent the fan from mechanical damages due to wears and tears. </p>

                                    <h3 className="cate-blw-cont-h3"> Elegant Designs: </h3>
                                    <p className="cate-blw-cont">Colorbot’s designer BLDC fans are crafted to bring style with functionality together. With variety of captivating color combination and designs, these stylish fans make your home’s ceiling a focal point. Additionally, the red and blue LED speed indicators on the motor style up the interior adding a touch of sophistication. </p>

                                    <h3 className="cate-blw-cont-h3"> Easy Control: </h3>
                                    <p className="cate-blw-cont">Now no more extra effort to control a fan using the conventional regulator! Colorbot BLDC ceiling fans with remote ease the controlling process effortlessly. Customize your comfort with 6 speed settings, 4 timer options or maximize the air with the Boost mode; you can now do any of these using a sleek, handy remote!  </p>

                                    <h3 className="cate-blw-cont-h3"> Functional All Year-Round: </h3>
                                    <p className="cate-blw-cont">Colorbot BLDC fans with reverse mode ensure your comfort throughout the year. The clockwise rotation of the fan helps in upward airflow, circulating the warm air in the room. This feature helps your room stay cozy during winters.  </p>

                                    <h3 className="cate-blw-cont-h3"> Low Voltage Operation: </h3>
                                    <p className="cate-blw-cont">Say goodbye to voltage fluctuations as the Colorbot fans are here to serve you more than just offering cool breeze.
                                        These fans work effectively even at low voltages with consistent speed and    also run 3 times longer on inverters compared to normal fans.
                                    </p>

                                    <h2 className="cate-blw-cont-h2"> Wide Range of BLDC Fans for Every Space:</h2>
                                    <p className="cate-blw-cont">Explore Colorbot for an upgraded comfort with a wide range of 3 and 4 blade BLDC fans. From superior performance and energy efficiency to being the style statement of the home, Colorbot fans have got multiple reasons to be the best BLDC fans available in India.  </p>
                                    <p className="cate-blw-cont">Be it minimalist modern design of Stella, contemporary look of Helix, or premium golden ring carved styling of Knoxx, the Colorbot fans are designed to be the ultimate style quotient of any space. Colorbot also offers an all new range of Dark edition, boasting its collection of Black ceiling fans with a matte finish.</p>
                                    <p className="cate-blw-cont">The Knoxx Quad and Stella Quad are one of our bestsellers under the  <NavLink to="/colorbot-knoxx-quad">4 blade ceiling fan</NavLink> category that come with 4 aerodynamic blades. Known for their higher air delivery, these designer 4 blade fans make your home feel much airy during hot humids or dry summers.</p>
                                    <p className="cate-blw-cont">Explore our latest collection of BLDC fans, offering the perfect combination of brilliance functioning, power efficiency, and refinement in homes. These 3 and 4 blade BLDC fans can seamlessly blend design, functionality and efficiency.</p>

                                    <h2 className="cate-blw-cont-h2">Why Choose Colorbot?</h2>

                                    <h3 className="cate-blw-cont-h3">Wide Availability:</h3>
                                    <p className="cate-blw-cont">Colorbot BLDC fans are readily available online, offering enhanced convenience and accessibility to customers across India. With free shipping and hassle-free online delivery, you can get your fan at your doorstep within 3 to 5 business days.</p>

                                    <h3 className="cate-blw-cont-h3">Competitive Pricing:</h3>
                                    <p className="cate-blw-cont">When buying Colorbot fans online, you can enjoy cost-effective pricing and attractive discounts, making them affordable choices for all budgets.</p>

                                    <h3 className="cate-blw-cont-h3">Effortless Installation:</h3>
                                    <p className="cate-blw-cont">Colorbot fans come with a simple installation and a user-friendly manual, making your installation process effortless, saving your effort and time.</p>

                                    <h3 className="cate-blw-cont-h3">Trusted Brand:</h3>
                                    <p className="cate-blw-cont">Colorbot is a trusted and reputed brand in the fan industry renowned for its commitment to providing quality, reliability, sustainability, and customer satisfaction, ensuring that you are investing in the right and effective products.</p>


                                    <h2 className="cate-blw-cont-h2">Key Points to Keep in Mind While Buying BLDC Fan</h2>

                                    <h3 className="cate-blw-cont-h3">Energy consumption:</h3>
                                    <p className="cate-blw-cont">When you are buying a ceiling fan, always consider its energy consumption. Go for BEE 5 star rated BLDC ceiling fan that takes less energy and saves costs on electricity bills. </p>

                                    <h3 className="cate-blw-cont-h3">Warranty coverage:</h3>
                                    <p className="cate-blw-cont">Make sure that the fan you are buying for your home has a good warranty so that it can be easily repaired and replaced in the future.  </p>

                                    <h3 className="cate-blw-cont-h3">After-sales service:</h3>
                                    <p className="cate-blw-cont">Look for the after-sales service of a fan that you are buying to deal with future trouble.   </p>

                                    <h3 className="cate-blw-cont-h3">Design of the fan:</h3>
                                    <p className="cate-blw-cont">When purchasing a ceiling fan, make sure the design perfectly aligns with your existing home interior.    </p>

                                    <h3 className="cate-blw-cont-h3">Control Option:</h3>
                                    <p className="cate-blw-cont">The control type of a fan is an important point to keep in mind. Modern fans come with remote control and other advanced features. Choose a fan with the control option that suits your preferences.    </p>

                                </>
                                    : ""}



                                {/* {showMore ? <p>  Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem </p> : ""} */}
                                <p className="text-center mt-4"><button className="btn view" onClick={() => {
                                    setShowMore(!showMore)
                                }}>{showMore ? "View Less" : "View More"}</button></p>
                                <br />
                            </div>
                            <div className="col-md-6">
                                <div className="accordion accordion-flush" id="accordionFlushExample">

                                    <h2 className="faq-head-product">Frequently Asked Questions</h2>

                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                How to buy a Colorbot BLDC fan?
                                            </button>
                                        </h3>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body cat-faq"><p>To buy a Colorbot fan online, you must log in to the Colorbot website at www.colorbot.in. Choose the fan according to your requirement by exploring the Product pages. Add the desired product(s) to your cart and proceed for a secure payment. You may select the payment option as per your wish and then proceed to check out. You will receive a notification on the page of successful purchase once the payment is successfully completed.  </p></div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                Are all Colorbot fans equipped with BLDC technology?
                                            </button>
                                        </h3>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body cat-faq"><p>Yes. All Colorbot fans are equipped with power saving BLDC (Brushless Direct Current) technology. This technology makes Colorbot fans highly energy efficient, great performers and noiseless even at maximum speed settings.  </p></div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="flush-headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                Can I buy a Colorbot BLDC ceiling fan online on Amazon and Flipkart?
                                            </button>
                                        </h3>
                                        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body cat-faq"><p>Yes, all the Colorbot BLDC ceiling fans are available on Amazon and Flipkart, you can easily buy Colorbot fans there with amazing offers.</p></div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="flush-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                Can I install the Colorbot BLDC fan on my own?
                                            </button>
                                        </h3>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body cat-faq"><p>Yes, you can install a fan on your own with the help of the user manual provided along with the fan. However, we suggest you take help from an experienced electrician if you are not habitual with the electricals. </p></div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="flush-headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                What accessories come with the Colorbot BLDC fan?
                                            </button>
                                        </h3>
                                        <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body cat-faq"><p>You get the Colorbot fan accessories such as a remote, canopy, set of blades, batteries, and downrod along with the fan. </p></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <p className="text-center mt-4"><NavLink to=""><button className="btn view">View More</button></NavLink></p> */}
                                <br />
                            </div>
                        </div>
                    </FadeInSection>
                </div>

                {width < 1000 ? <div className="filtericon" onClick={() => {
                    handleButtonClick()
                }}>
                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/filter.svg" alt="" className="img-fluid" width="40" height="40" /> <p className="filter-text">Filter</p>
                </div> : ""}
                <div className={sidePanelOpen ? "sidebar-overlayactive sidebar-overlay" : "sidebar-overlay"}></div>
                <div
                    id="sidePanel"
                    className={sidePanelOpen ? 'open overlay' : ''}
                >
                    <div className="sidepanel-content">

                        <span className="cartclose" onClick={() => { handleCloseClick() }}> <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/closecart.svg" alt="close" className="img-fluid" width="16" height="16" /></span>
                    </div>
                    <br />
                    <div className="filter">
                        {/* <div id="sortby">

                            <div className="title">
                                Sort by
                            </div>


                            <div className="form-check mt-2">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                <label className="form-check-label labeltitle" htmlFor="flexRadioDefault2">
                                    Relevance
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                <label className="form-check-label labeltitle" htmlFor="flexRadioDefault3">
                                    Price (Low to High)
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                <label className="form-check-label labeltitle" htmlFor="flexRadioDefault4">
                                    Price (High to Low)
                                </label>
                            </div>
                        </div> */}
                        <br />
                        <div id="product">
                            <div className="title">
                                Products
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox" value="Helix" id="flexCheckDefault" onClick={() => {
                                    updateproducttype("Helix")

                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault" >
                                    Helix
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="Knoxx" id="flexCheckDefault1" onClick={() => {
                                    updateproducttype("Knoxx")
                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault1">
                                    Knoxx
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="Stella" id="flexCheckDefault2" onClick={() => {
                                    updateproducttype("Stella")
                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault2">
                                    Stella
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="Knoxx-Quad" id="flexCheckDefault3" onClick={() => {
                                    updateproducttype("Knoxx-Quad")
                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault3">
                                    Knoxx Quad
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="Stella-Quad" id="flexCheckDefault4" onClick={() => {
                                    updateproducttype("Stella-Quad")
                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault4">
                                    Stella Quad
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="Prizma" id="flexCheckDefault5" onClick={() => {
                                    updateproducttype("Prizma")
                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault5">
                                    Prizma
                                </label>
                            </div>
                        </div>
                        <br />
                        <div id="color">
                            <div className="title">
                                Colors
                            </div>
                            {colorlist.map((item, index) => {
                                return (
                                    <div className="form-check mt-2" key={index}>
                                        <input className="form-check-input" type="checkbox" value="" id={`flexCheckDefaultcolor${index}`} onClick={() => {
                                            getActiveColorNameknoxx(item.color)

                                        }} />
                                        <label className="form-check-label labeltitle" htmlFor={`flexCheckDefaultcolor${index}`}>
                                            {item.value}
                                        </label>
                                    </div>
                                )
                            })}


                        </div>
                        <br />
                        <div id="types">
                            <div className="title">
                                Types
                            </div>

                            {/* <div className="form-check mt-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaulttype" />
                                    <label className="form-check-label labeltitle" htmlFor="flexCheckDefaulttype">
                                        With remote
                                    </label>
                                </div> */}

                            {/* <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1type" />
                                    <label className="form-check-label labeltitle" htmlFor="flexCheckDefault1type">
                                        Without remote
                                    </label>
                                </div> */}

                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox" value="with_led" id="flexCheckDefault2type" onChange={(e) => {
                                    if (e.target.checked) {
                                        setcheckLed((prev) => [...prev, "with_led"])
                                    } else {
                                        setcheckLed((prev) => prev.filter(item => item !== "with_led"))
                                    }

                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault2type">
                                    With LED
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="without_led" id="flexCheckDefault3type" onChange={(e) => {
                                    if (e.target.checked) {
                                        setcheckLed((prev) => [...prev, "without_led"])
                                    } else {
                                        setcheckLed((prev) => prev.filter(item => item !== "without_led"))
                                    }


                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault3type">
                                    Without LED
                                </label>
                            </div>

                        </div>
                        <br />
                        {/* <div id="availability">
                        <div className="title">
                            Availability
                        </div>

                        <div className="form-check mt-2">
                            <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefaultavailability" />
                            <label className="form-check-label labeltitle" htmlFor="flexRadioDefaultavailability">
                                Offers
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefaultavailability1" />
                            <label className="form-check-label labeltitle" htmlFor="flexRadioDefaultavailability1">
                                Latest
                            </label>
                        </div>

                    </div> */}
                        <br />
                        <div id="price">
                            <div className="title">
                                Price
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultprice" onChange={(e) => {
                                    if (e.target.checked) {
                                        setpriceArray([...priceArray, {
                                            firstprice: 2000,
                                            secondprice: 3000
                                        }])
                                    } else {
                                        setpriceArray(priceArray?.filter(price => !(price.firstprice === 2000 && price.secondprice === 3000)));
                                    }
                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefaultprice">
                                    <i className="fa fa-inr" aria-hidden="true"></i>2000 - <i className="fa fa-inr" aria-hidden="true"></i>3000
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1price" onChange={(e) => {
                                    if (e.target.checked) {
                                        setpriceArray([...priceArray, {
                                            firstprice: 3000,
                                            secondprice: 4000

                                        }])
                                    } else {
                                        setpriceArray(priceArray?.filter(price => !(price.firstprice === 3000 && price.secondprice === 4000)));
                                    }

                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault1price">
                                    <i className="fa fa-inr" aria-hidden="true"></i>3000 - <i className="fa fa-inr" aria-hidden="true"></i>4000
                                </label>
                            </div>
                            <div className="form-check mb-5">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2price" onChange={(e) => {
                                    if (e.target.checked) {
                                        setpriceArray([...priceArray, {
                                            firstprice: 4000,
                                            secondprice: 5000
                                        }])
                                    } else {
                                        setpriceArray(priceArray?.filter(price => !(price.firstprice === 4000 && price.secondprice === 5000)));
                                    }
                                }} />
                                <label className="form-check-label labeltitle" htmlFor="flexCheckDefault2price">
                                    <i className="fa fa-inr" aria-hidden="true"></i>4000 - <i className="fa fa-inr" aria-hidden="true"></i>5000
                                </label>
                            </div>
                            {/* <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault3price" />
                                    <label className="form-check-label labeltitle" htmlFor="flexCheckDefault3price">
                                        <i className="fa fa-inr" aria-hidden="true"></i>5000 - <i className="fa fa-inr" aria-hidden="true"></i>6000
                                    </label>
                                </div> */}

                        </div>
                    </div>




                </div>




            </section>

    );

}
export default Products;