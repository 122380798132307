import "./order.css"
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
function Order(props) {
    const [orderempty, setOrderEmpty] = useState(null)
    const [lgShow1, setLgShow1] = useState(false);
    const navigate = useNavigate()

    function Logout() {
        setLgShow1(true)

    }
    function Logoutmain() {
        window.localStorage.setItem('LoggedIn', false)
        props.setLoggedIn(false)
        window.localStorage.clear()
        navigate("/")
    }
    function getdetail(index) {
        window.localStorage.setItem("orderdetail", JSON.stringify(order[index]))
        navigate("/order_detail")
    }
    const [order, setOrder] = useState([])
    useEffect(() => {
        getorder()
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

    }, [])

    useEffect(() => {
        document.title = "Orders - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Orders - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    // LOADER CODE
    const [showLoader, setShowLoader] = useState(true)

    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const convertdate = (date) => {

        var d = date?.split("-")[0];
        var m = date?.split("-")[1];
        var y = date?.split("-")[2];
        var time = d + " " + month[parseInt(m) - 1] + " " + y;
        return time;
    }

    async function getorder() {
        try {
            await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetOrders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },

                body: JSON.stringify({ Phone: window.localStorage.getItem('phoneNumber') }),
            }).then((res) => res.json()).then((response) => {


                if (response.status === 200) {
                    if (response.data.length === 0) {
                        setOrderEmpty(true)
                    } else {
                        setOrderEmpty(false)
                        setOrder(response.data)
                    }


                    setShowLoader(false)







                } else {
                    setOrderEmpty(true)
                    setShowLoader(false)

                }
            })
        } catch (error) {
            setOrderEmpty(true)
            setShowLoader(false)
            console.error('API Error:', error);

        }
    }
    const navigateToProduct = (path) => {
        navigate(path);
    }
    return (

        showLoader ? <>
            <section className="mt-5 pt-4 loader-align">
            <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loader-gif.gif"></img>
              <p>LOADING COLORBOT...</p>
            </section>
        </>
            :
      
            <section className="signback mt-5 pt-3 pb-5">
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"

                    centered
                    show={lgShow1}

                    onHide={() => setLgShow1(false)}

                >
                    <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0rem" }}>


                        <div className="box-profile-pop-profile">



                            <div className="logoutpop">
                                <img src="assets/Vector.svg" className="img-fluid" alt="exlam" />
                            </div>
                            <div className="logoutpop mt-2">
                                <p>Are you sure you want to logout?</p>
                            </div>
                            <div className="logoutpop button">
                                <button className="btn btncancellog" onClick={() => setLgShow1(false)}>
                                    Cancel
                                </button>
                                <button className="btn btnsubmitlog" onClick={() => {
                                    Logoutmain()
                                }}>Logout</button>
                            </div>







                        </div>


                    </Modal.Body>
                </Modal>
                <div className="container">
                    <div className="row justify-content-center mt-5">
                        <div className="col-lg-3 profilecolwidth mt-3">
                            <div className="profile_left">

                                <NavLink to="/Order" className="nodecoration">  <div className="tab_left tab-leftactive">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/myorder_left.svg" className="img-fluid" alt="order" />&nbsp;&nbsp;&nbsp; My Orders
                                </div></NavLink>
                                <NavLink to="/profile" className="nodecoration"> <div className="tab_left ">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/profile_left.svg" className="img-fluid" alt="profile" />&nbsp;&nbsp;&nbsp;&nbsp; Profile
                                </div></NavLink>
                                <div className="tab_left" onClick={() => {
                                    Logout()
                                }}>
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/logout_left.svg" className="img-fluid" alt="logout" />&nbsp;&nbsp;&nbsp;&nbsp; Logout
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 mt-3">
                            <div className="modal-box">
                                {orderempty ?
                                    <div >
                                        <div className="box-image">
                                            <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/empty.svg" alt="" className="img-fluid" />
                                        </div>
                                        <div className="mainbox-para text-center">
                                            <span>You don’t have any orders yet.</span>
                                        </div>

                                        <NavLink to="/bldc-ceiling-fan">  <div className="button-my-order-empty">
                                            <input className="btn button-signin " type="submit" value="Keep Shopping" />
                                        </div></NavLink>
                                    </div>
                                    :
                                    <div className="row mt-3">
                                        {order.length > 0 && order.map((item, index) => {
                                            // Calculate totals for the first item in OrderDetail
                                            let totalQuantity = 0;
                                            let totalSubTotal = 0;


                                            item.OrderDetail.forEach((orderDetailItem) => {
                                                totalQuantity += parseInt(orderDetailItem.OrderQuantity);
                                                totalSubTotal += parseInt(orderDetailItem.OrderSubTotal);

                                            });

                                            return (
                                                <div className="col-md-12" key={index}>
                                                    <div className="box-1-1">
                                                        <div className="product-image">
                                                            <img src={item.OrderDetail[0].OrderImage} className="image-fluid" alt="" style={{
                                                                cursor: "pointer"
                                                            }} onClick={() => {
                                                                navigateToProduct(item.OrderDetail[0].ProductPath)
                                                            }} />
                                                        </div>
                                                        <div className="product-direction-manage">
                                                            <div className="left-content">
                                                                <div className="order-id">
                                                                    <span>{item.OrderId}</span>
                                                                </div>
                                                                <div className="product-name">
                                                                    <span>{item.OrderDetail[0].OrderName} {item.OrderDetail.length > 1 ? "+ " + parseInt(item.OrderDetail.length - 1) : ""}</span>
                                                                </div>
                                                                <div className="quantity">
                                                                    <span>Qty: {totalQuantity}</span>
                                                                </div>
                                                                <div className="price-of-product">
                                                                    <span>Rs. {totalSubTotal - parseInt(item.OrderCoupanPrice)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="right-content">
                                                                {item.OrderStatus !== "Cancelled" && item.OrderStatus !== "RTO" ?
                                                                    item.OrderStatus === "Order Confirmed" ?
                                                                        <div className="product-status-conf">
                                                                            <span className="text-secondary">{item.OrderStatus}</span>
                                                                        </div> : item.OrderStatus === "Delivered" ? <div className="product-status-conf">
                                                                            <span className="text-success">{item.OrderStatus}</span>
                                                                        </div> : <div className="product-status-conf">
                                                                            <span>{item.OrderStatus}</span>
                                                                        </div> : <div className="product-status-conf">
                                                                        <span className="text-danger">{item.OrderStatus}</span>
                                                                    </div>}
                                                                <div className="date-of-purchase">
                                                                    {item.OrderStatus == "Cancelled" ? <span>{item?.OrderCancelledDate !== undefined ? convertdate(item?.OrderCancelledDate) : ""}</span> : <span>{item?.OrderStatus == "Order Confirmed" ? convertdate(item?.OrderDate) : ""}{item.OrderStatus === "Shipped" ? convertdate(item?.OrdershippedDate) :""}{ item.OrderStatus === "RTO" ? convertdate(item?.OrderRTODate):""}{item.OrderStatus === "IN TRANSIT" ? convertdate(item?.OrderPickedDate) :""}{item.OrderStatus === "Delivered"? convertdate(item?.OrderDeliveredDate):""}</span>}
                                                                </div>
                                                                <div>
                                                                    <input className="btn button-my-order button-signin" type="submit" value="View Details" onClick={() => { getdetail(index) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}


                                    </div>}




                            </div>

                        </div>


                    </div>
                </div>
            </section>
    
    )
}
export default Order;